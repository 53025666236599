import event_style from "./event_card.module.css";
import common_style from "../../../common/css/common.module.css";

import color_palette from "../../../common/types/colors";

// Author: Jack Hau

type EventCardStyling = {
	card_root_style: any;
	date_style: any;
	image_style: any;
	name_style: any;
	location_date_style: any;
	price_style: any;
};

type Props = {
	event_date: any;
	event_name: string;
	event_image: any;
	event_location: string;
	custom_styles?: EventCardStyling;
	onClick: Function;
	event_subtitle?: string;
};

// this is an object for default styling
const default_styles: EventCardStyling = {
	card_root_style: event_style.root,
	date_style: event_style.event_p,
	image_style: event_style.event_image,
	name_style: event_style.event_p,
	location_date_style: event_style.event_p,
	price_style: event_style.event_p,
};

const EventCard = ({ event_date, event_name, event_image, event_location, custom_styles, onClick, event_subtitle }: Props) => {
	const { card_root_style, date_style, image_style, name_style, location_date_style, price_style } =
		custom_styles === undefined ? default_styles : custom_styles;

	const style: EventCardStyling = {
		card_root_style: card_root_style,
		date_style: date_style,
		image_style: image_style,
		name_style: name_style,
		location_date_style: location_date_style,
		price_style: price_style,
	};

	// Helper function to format the time till event section of the event card
	const format_time = (date: Date) => {
		const today: Date = new Date();

		const js_date: Date = new Date(date);

		const curr_month: number = today.getMonth() + 1;
		const event_month: number = js_date.getMonth() + 1;
		const curr_year: number = today.getFullYear();
		const event_year: number = js_date.getFullYear();

		if (event_month - curr_month === 0) {
			const diff = js_date.getDate() - today.getDate();

			switch (diff) {
				case 0: {
					return "Today!";
				}
				case 1: {
					return "Tomorrow!";
				}
				default: {
					return diff + " days away!";
				}
			}
		} else if (event_year - curr_year === 0) {
			const diff = event_month - curr_month;

			switch (diff) {
				case 1: {
					return "Next month!";
				}
				default: {
					return diff + " months away!";
				}
			}
		} else {
			const diff = event_year - curr_year;

			switch (diff) {
				case 1: {
					return "Next year!";
				}
				default: {
					return diff + " years away!";
				}
			}
		}
	};

	// Helper function to format the date and location section of the event card
	const format_date_location = (date: Date, location: string) => {
		const date_mapper = ["Jan.", "Feb.", "March", "April", "May", "June", "July", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];
		const js_date: Date = new Date(date);
		const day = js_date.getDate();
		const month = date_mapper[js_date.getMonth()];

		switch (day) {
			case 1: {
				return month + " " + day + "st @ " + location;
			}
			case 2: {
				return month + " " + day + "nd @ " + location;
			}
			case 3: {
				return month + " " + day + "rd @ " + location;
			}
			case 21: {
				return month + " " + day + "st @ " + location;
			}
			case 22: {
				return month + " " + day + "nd @ " + location;
			}
			case 23: {
				return month + " " + day + "rd @ " + location;
			}
			case 31: {
				return month + " " + day + "st @ " + location;
			}
			default: {
				return month + " " + day + "th @ " + location;
			}
		}
	};

	return (
		<div className={`${style.card_root_style} ${common_style.common_font_small}`} style={{ color: color_palette.black }}>
			<p className={`${style.date_style} ${common_style.common_bold}`} style={{ color: color_palette.dark_blue }}>
				{format_time(event_date)}
			</p>
			<div
				className="flex cursor-pointer items-center justify-center self-center overflow-hidden rounded-lg bg-[#808080] md:h-[270px] md:w-[350px]"
				onClick={() => onClick()}
			>
				<img src={event_image} alt="Event Pic" className=" cursor-pointer md:w-[350px]" onClick={() => onClick()} />
			</div>
			<p className={`${style.name_style} ${common_style.common_bold}`}>{event_name}</p>
			{event_subtitle && (
				<p className={`${style.name_style} ${common_style.common_medium}`} style={{ color: color_palette.gray }}>
					{event_subtitle}
				</p>
			)}
			<p className={`${style.location_date_style} ${common_style.common_medium}`} style={{ color: color_palette.gray }}>
				{format_date_location(event_date, event_location)}
			</p>
		</div>
	);
};

export default EventCard;
