type Props = {
	checked: boolean;
	check_box_class?: string;
	disabled?: boolean;
	id?: any;
	key?: number;
	label: React.ReactNode;
	label_class?: string;
	value?: string;
	onChange: Function;
};

const RadioButton = ({ checked, check_box_class, disabled, id, key, label, label_class, value, onChange }: Props) => {
	const check_box_style: string =
		check_box_class ??
		`
            m-0 
            mr-[10px] 
            p-0 
            appearance-none 
            bg-white 
            w-[16px] 
            h-[16px] 
            border-2 
            border-light-blue 
            hover:cursor-pointer 
            hover:border-medium-blue 
            checked:border-dark-blue 
			checked:bg-dark-blue
        `;

	const label_style: string =
		label_class ??
		`
            m-0 
            p-0 
            text-left 
			font-custom 
			font-normal 
			text-regular 
			text-fangarde-black 
            flex 
            flex-row 
            items-center 
            h-full 
        `;

	return (
		<label className={label_style} key={key}>
			<input
				id={id}
				className={check_box_style}
				disabled={disabled}
				type={"radio"}
				value={value}
				checked={checked}
				onChange={(event) => {
					const value: string = event.target.value;

					onChange(value);
				}}
			/>
			{label}
		</label>
	);
};

export default RadioButton;
