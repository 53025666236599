import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import InputBox from "components/input_box";
import Dropdown from "components/dropdown";
import TextButton from "components/text_button";
import TicketBox from "pages/events/tickets_page/ticket_box";
import SmallButton from "components/small_button/small_button";
import ErrorCode400 from "components/error_displays/400_error_code";

import { use_events } from "state/use_events";
import color_palette from "common/types/colors";

type Filters = {
	search: string;
	high_price: number;
	low_price: number;
};

const default_price_filters: Array<number> = [0, 1, 5, 10, 15, 20, 25, 35, 45, 55, 65, 75, 85, 100, 200, 300, 400, 500];

const default_filters: Filters = {
	search: "",
	high_price: -1,
	low_price: -1,
};

const Tickets = () => {
	const { getValues, handleSubmit, register, reset, setValue } = useForm<Filters>({
		defaultValues: default_filters,
		mode: "onChange",
		reValidateMode: "onChange",
	});

	const ticket_options = use_events((state) => state.ticket_options);
	const previously_selected_tickets = use_events((state) => state.selected_tickets);
	const selected_event = use_events((state) => state.selected_event);
	const set_selected_tickets_for_checkout = use_events((state) => state.set_selected_tickets);
	const set_paid = use_events((state) => state.set_paid);

	const navigate = useNavigate();

	const [warn, set_warn] = useState<boolean>(false);
	const [tickets, set_tickets] = useState<Array<TicketType>>(ticket_options);
	const [selected_tickets, set_selected_tickets] = useState<Array<SelectedTicket>>(
		previously_selected_tickets ? previously_selected_tickets : []
	);
	const [local_num_tickets, set_local_num_tickets] = useState<Array<number>>(
		ticket_options?.map((ticket_option) => {
			return ticket_option.number_of_tickets;
		})
	);

	const { primary_color, secondary_color } = selected_event?.color_scheme ?? {
		primary_color: undefined,
		secondary_color: undefined,
	};

	const clear = () => {
		reset();
		set_tickets(ticket_options);
	};

	const filter = () => {
		const { search, high_price, low_price }: Filters = getValues();
		let filtered_tickets: Array<TicketType> = new Array(...ticket_options);

		filtered_tickets = filtered_tickets.filter(
			({ ticket_name, ticket_tier }) =>
				ticket_name.toLowerCase().includes(search.toLowerCase()) || ticket_tier.toLowerCase().includes(search.toLowerCase())
		);

		if (high_price !== -1 || low_price !== -1) {
			filtered_tickets = filtered_tickets.filter((ticket: TicketType) => {
				const { price } = ticket;

				if (
					high_price !== -1 &&
					low_price !== -1 &&
					Number(price.$numberDecimal) <= high_price &&
					Number(price.$numberDecimal) >= low_price
				) {
					return ticket;
				} else if (high_price !== -1 && Number(price.$numberDecimal) <= high_price && low_price === -1) {
					return ticket;
				} else if (low_price !== -1 && Number(price.$numberDecimal) >= low_price && high_price === -1) {
					return ticket;
				} else {
					return false;
				}
			});
		}

		set_tickets(filtered_tickets);
	};

	const go_back = () => {
		set_selected_tickets_for_checkout([]);
		navigate("/events/event_overview");
	};

	const go_forward = async () => {
		if (selected_tickets.length > 0) {
			let paid = false;

			selected_tickets?.map((order) => {
				if (Number(order.ticket.price.$numberDecimal) > 0) {
					paid = true;
				}
				return order;
			});

			set_selected_tickets_for_checkout(selected_tickets);
			set_paid(paid);
			navigate("/events/billing_info");
		} else {
			set_warn(true);
		}
	};

	const on_add_ticket = (added_ticket: TicketType) => {
		set_warn(false);

		let tickets: Array<SelectedTicket> = new Array(...selected_tickets);
		let ticket_type_idx: number = -1;

		tickets?.map(({ ticket }, idx) => {
			if (ticket.ticket_name === added_ticket.ticket_name && ticket.ticket_tier === added_ticket.ticket_tier) {
				ticket_type_idx = idx;
			}
			return ticket;
		});

		if (ticket_type_idx === -1) {
			tickets.push({ ticket: added_ticket, quantity: 1 });
		} else {
			tickets[ticket_type_idx].quantity += 1;
		}

		set_selected_tickets(tickets);
	};

	const on_remove_ticket = (added_ticket: TicketType) => {
		let tickets: Array<SelectedTicket> = new Array(...selected_tickets);
		let ticket_type_idx: number = -1;

		tickets?.map(({ ticket }, idx) => {
			if (ticket.ticket_name === added_ticket.ticket_name && ticket.ticket_tier === added_ticket.ticket_tier) {
				ticket_type_idx = idx;
			}
			return ticket;
		});

		if (tickets[ticket_type_idx].quantity === 1) {
			tickets = tickets.filter((item, idx) => ticket_type_idx !== idx);
		} else {
			tickets[ticket_type_idx].quantity -= 1;
		}

		set_selected_tickets(tickets);
	};

	const set_filters = (type: string, value: number) => {
		switch (type) {
			case "high": {
				if (getValues("low_price") > -1 && value < getValues("low_price")) {
					setValue("high_price", 500);

					break;
				}

				setValue("high_price", value);

				break;
			}
			case "low": {
				if (getValues("high_price") > -1 && value > getValues("high_price")) {
					setValue("low_price", 1);

					break;
				}

				setValue("low_price", value);

				break;
			}
		}
	};

	function Buttons() {
		return (
			<div className="mx-auto flex flex-row justify-between md:w-[500px]">
				<SmallButton
					default_color={primary_color}
					hover_color={secondary_color}
					active_color={primary_color}
					onClick={go_back}
				>
					Back
				</SmallButton>
				<SmallButton
					default_color={primary_color}
					hover_color={secondary_color}
					active_color={primary_color}
					onClick={go_forward}
				>
					Buy Tickets
				</SmallButton>
			</div>
		);
	}

	const ticket_box_styles = "mx-auto flex w-11/12 mb-8 flex-col items-center md:w-full";

	if (!selected_event) return <ErrorCode400 />;
	else
		return (
			<div className="mx-auto w-11/12 md:my-8 md:w-1/2" style={{ color: color_palette.black }}>
				<div className="mb-12 flex w-full flex-col md:flex-row">
					<h1 className="my-0 mb-4 mr-6 pt-8 text-center text-3xl font-semibold md:pt-0">Filter By</h1>
					<form
						className={`flex flex-col pl-6 text-xl font-semibold`}
						style={{ borderLeft: `4px solid ${primary_color ?? color_palette.medium_blue}` }}
						onChange={handleSubmit(filter)}
					>
						<div className="mb-4 flex h-[40px] w-full flex-row items-center">
							<p
								className="mr-[10px] h-full w-1/6"
								style={{ borderRight: `3px solid ${primary_color ?? color_palette.medium_blue}` }}
							>
								Seat Type
							</p>
							<div className="h-full w-5/6">
								<InputBox
									{...register("search")}
									type="text"
									value={getValues("search").toString()}
									placeholder="Search for specific seats or tickets..."
									onChange={(value: string) => setValue("search", value)}
									default_color={primary_color}
									hover_color={secondary_color}
									focus_color={secondary_color}
								/>
							</div>
						</div>
						<div className="flex flex-row items-center">
							<p
								className={"mr-[10px] h-full w-1/6"}
								style={{ borderRight: `3px solid ${primary_color ?? color_palette.medium_blue}` }}
							>
								Price
							</p>
							<div className="flex flex-col items-center space-x-4 md:flex-row">
								<div className={"flex flex-row items-center"}>
									<p
										className={`
											text-bold 
											min-w-[105px] 
											font-custom 
										`}
										style={{ color: primary_color ?? color_palette.medium_blue }}
									>
										High Price:{" "}
									</p>
									<Dropdown
										{...register("high_price")}
										options={default_price_filters}
										value={getValues("high_price").toString()}
										renderOption={(option: string, index: any) => {
											return (
												<option value={option} key={index}>
													{option}
												</option>
											);
										}}
										onChange={(value: string) => set_filters("high", Number(value))}
										default_color={primary_color}
										hover_color={secondary_color}
										focus_color={secondary_color}
									/>
								</div>
								<div className={"flex flex-row items-center"}>
									<p
										className={`
											text-bold 
											min-w-[105px] 
											font-custom 
										`}
										style={{ color: primary_color ?? color_palette.medium_blue }}
									>
										Low Price:{" "}
									</p>
									<Dropdown
										{...register("low_price")}
										options={default_price_filters}
										value={getValues("low_price").toString()}
										renderOption={(option: string, index: any) => {
											return (
												<option value={option} key={index}>
													{option}
												</option>
											);
										}}
										onChange={(value: string) => set_filters("low", Number(value))}
										default_color={primary_color}
										hover_color={secondary_color}
										focus_color={secondary_color}
									/>
								</div>
								<div className={"w-[99px]"}>
									<TextButton
										label={"Reset Filters"}
										onClick={() => clear()}
										default_color={primary_color}
										hover_color={secondary_color}
										active_color={primary_color}
									/>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div className={ticket_box_styles}>
					{tickets.length ? (
						tickets?.map((ticket, idx) => {
							const now = new Date(Date.now());
							const release = new Date(ticket.release_time);
							const stop = new Date(ticket.stop_sale_time);

							const ticket_price = Number(ticket.price.$numberDecimal);

							if (release > now || stop < now) {
								return null;
							}

							if (local_num_tickets[idx] === 0) {
								return null;
							}

							return (
								<TicketBox
									key={ticket._id}
									name={ticket.ticket_name}
									ticket_price={ticket_price}
									ticket_tier={ticket.ticket_tier}
									color={primary_color}
									number_of_tickets={ticket.number_of_tickets}
									onClick={() => {
										const idx = tickets.findIndex((selected_ticket) => selected_ticket._id === ticket._id);

										let updated_local_num_tickets = new Array(local_num_tickets)[0];

										updated_local_num_tickets[idx] -= 1;

										set_local_num_tickets(updated_local_num_tickets);

										on_add_ticket(ticket);
									}}
									default_color={primary_color}
									hover_color={secondary_color}
									active_color={primary_color}
								/>
							);
						})
					) : (
						<h3>No tickets exist for this event!</h3>
					)}
				</div>
				<div className="w-full">
					<p className="text-center text-3xl">Selected Tickets</p>
					<div className={ticket_box_styles}>
						{selected_tickets.length ? (
							selected_tickets?.map(({ ticket, quantity }) => (
								<TicketBox
									name={ticket.ticket_name}
									ticket_price={Number(ticket.price.$numberDecimal)}
									ticket_tier={ticket.ticket_tier}
									onClick={() => {
										const idx = tickets.findIndex((selected_ticket) => selected_ticket._id === ticket._id);

										let updated_local_num_tickets = new Array(local_num_tickets)[0];

										updated_local_num_tickets[idx] += 1;

										set_local_num_tickets(updated_local_num_tickets);
										on_remove_ticket(ticket);
									}}
									selected_ticket={true}
									quantity={quantity}
									color={primary_color}
									default_color={primary_color}
									hover_color={secondary_color}
									active_color={primary_color}
								/>
							))
						) : (
							<p className="mt-4 text-lg">When you add tickets, they'll appear here!</p>
						)}
					</div>
				</div>
				{warn && <p className="py-2 text-center text-red-500">Please select at least one ticket to continue!</p>}
				<Buttons />
			</div>
		);
};

export default Tickets;
