type Props = {
	message: string;
	className?: string;
};

const ErrorMessage = ({ className, message }: Props) => {
	const message_style: string =
		className ??
		`
			w-full 
			font-custom 
			font-bold 
			text-regular 
			text-fangarde-medium-red 
			whitespace-normal 
        `;

	return <p className={message_style}>{message}</p>;
};

export default ErrorMessage;
