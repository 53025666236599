import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";

import { api } from "axiosClients/client";

import BillingSummaryBox from "../../../components/billing_summary_components/billing_summary_box";
import CheckBox from "components/check_box";
import Dropdown from "components/dropdown";
import ErrorCode400 from "components/error_displays/400_error_code";
import ErrorMessage from "components/error_message";
import Loader from "partials/loader/loader";
import InputBox from "components/input_box";
import SmallButton from "components/small_button/small_button";

import { useUser } from "state/useUser";
import { use_events } from "state/use_events";

import styles from "./billing_page.module.css";

import { font_style, input_style } from "common/styles/stripe/stripe_styles";

import { ButtonTypes, DonationType } from "common";

type Data = {
	currency: string;
	name: string;
	address_line1: string;
	address_city: string;
	address_state: string;
	address_zip: string;
	address_country: string;
};

type Donation = {
	donation_type: string;
	donation_amount: number;
};

type Payload = {
	orders?: Array<{ id: any; quantity: number }>;
	organization: any;
	stripe_token: any;
	email: String;
	event: any;
	user: {
		user_id: any;
		first_name: String;
		last_name: String;
	};
	promo_code?: String;
	donation?: {
		donation_amount: number;
		donation_type: string;
	};
	paid_tickets?: boolean;
	opted_in: boolean;
};

type Warning = {
	first_name: boolean;
	last_name: boolean;
	email: boolean;
	confirm_email: boolean;
	card_number: boolean;
	expiration_date: boolean;
	security_code: boolean;
	billing_address: boolean;
	country: boolean;
	city: boolean;
	state: boolean;
	zip_code: boolean;
	promo_code: boolean;
	donation: boolean;
};

type UserInformation = {
	first_name: string;
	last_name: string;
	email: string;
	confirm_email: string;
};

const BillingPage = () => {
	const {
		clearErrors: clearPromoCodeErrors,
		formState: promoCodeErrors,
		getValues: getPromoCodeValue,
		handleSubmit: handlePromoCodeSubmission,
		register: registerPromoCode,
		reset: resetPromoCode,
		setValue: setPromoCodeValue,
	} = useForm<{ promo_code: string }>({
		defaultValues: { promo_code: "" },
		mode: "onSubmit",
		reValidateMode: "onSubmit",
	});

	const {
		clearErrors: clearBillingInfoErrors,
		formState: billingInfoErrors,
		getValues: getBillingInfoValues,
		handleSubmit: handleBillingInfoSubmission,
		register: registerBillingInfo,
		reset: resetBillingInfo,
		setValue: setBillingInfoValue,
	} = useForm<BillingInformation & UserInformation>({
		defaultValues: {
			first_name: "",
			last_name: "",
			email: "",
			confirm_email: "",
			card_number: "",
			expiration_date: "",
			security_code: "",
			billing_address: "",
			country: "",
			city: "",
			state: "",
			zip_code: "",
		},
		mode: "onChange",
		reValidateMode: "onChange",
	});

	const user = useUser((state) => state.user);
	const {
		set_tickets_for_confirmation,
		set_paid,
		set_selected_tickets,
		set_checkout_donation,
		set_promo_discount,
		selected_organization,
		selected_event,
		selected_tickets,
		paid,
	} = use_events((state) => ({
		set_tickets_for_confirmation: state.set_tickets_for_checkout,
		set_paid: state.set_paid,
		set_selected_tickets: state.set_selected_tickets,
		set_checkout_donation: state.set_donation,
		set_promo_discount: state.set_promo_discount,
		selected_event: state.selected_event,
		selected_tickets: state.selected_tickets,
		selected_organization: state.selected_organization,
		paid: state.paid,
	}));

	const navigate = useNavigate();

	const stripe = useStripe();
	const elements = useElements();

	const [loading, set_loading] = useState<boolean>(true);
	const [purchase_failure, set_purchase_failure] = useState<boolean>(false);
	const [billing_info_errors, set_billing_info_errors] = useState<{ number: string; exp: string; cvc: string }>({
		number: "",
		exp: "",
		cvc: "",
	});
	const [warn, set_warn] = useState<Warning>({
		first_name: false,
		last_name: false,
		email: false,
		confirm_email: false,
		card_number: false,
		expiration_date: false,
		security_code: false,
		billing_address: false,
		country: false,
		city: false,
		state: false,
		zip_code: false,
		promo_code: false,
		donation: false,
	});
	const [confirm_promo_code, set_confirm_promo_code] = useState<{
		promo_code: string;
		discount: number;
	}>({
		promo_code: "",
		discount: 0,
	});
	const [opt_in, set_opt_in] = useState<boolean>(
		selected_organization?.mailing_list ? selected_organization?.mailing_list?.includes(user.email) : false
	);
	const [error_message, set_error_message] = useState<string>("");
	const [donation, set_donation] = useState<Donation>({
		donation_amount: 0,
		donation_type: "None",
	});
	const [checks_passed, set_checks_passed] = useState<boolean>(
		!paid && !donation.donation_amount && donation.donation_type === "None"
	);

	const { primary_color, secondary_color } = selected_event?.color_scheme ?? {
		primary_color: undefined,
		secondary_color: undefined,
	};

	const disallow_promo: boolean = selected_tickets
		? selected_tickets?.reduce((acc: number, item: SelectedTicket) => {
				return acc + Number(item.ticket.price.$numberDecimal);
		  }, 0) <= 0
		: false;

	const compute_total_cost = (ticket_price: number, quantity: number) => {
		let fangarde_flat_fee: number = 1;
		let fangarde_percent_fee: number = 0.01;
		let processing_flat_fee: number = 0.3;
		let processing_percent_fee: number = 0.0299;

		let sales_tax: number = 0;

		let pre_stripe_percent_final_cost: number =
			quantity * (ticket_price + fangarde_flat_fee + fangarde_percent_fee * ticket_price + ticket_price * sales_tax) +
			processing_flat_fee;

		let final_cost: any = pre_stripe_percent_final_cost * processing_percent_fee + pre_stripe_percent_final_cost;

		final_cost = final_cost.toFixed(2).toString().split("").reverse();

		let final_cost_cents: any = final_cost.slice(0, 3);
		final_cost = final_cost.splice(3, final_cost.length - 1);

		let formatted_cost: Array<string> = [];

		for (let i: number = 0; i < final_cost.length; i++) {
			formatted_cost.push(final_cost[i]);
			if (i % 3 === 2 && i !== final_cost.length - 1) {
				formatted_cost.push(",");
			}
		}

		return formatted_cost.reverse().join("") + final_cost_cents.reverse().join("");
	};

	const go_back = () => {
		const original_tickets = selected_tickets?.map(({ ticket, quantity }) => {
			return {
				ticket: {
					...ticket,
					price: {
						$numberDecimal: (Number(ticket.price.$numberDecimal) / (1 - confirm_promo_code.discount)).toString(),
					},
				},
				quantity: quantity,
			};
		});

		set_selected_tickets(original_tickets);
		set_promo_discount(undefined);
		resetPromoCode();
		clearPromoCodeErrors();
		navigate("/events/buy_tickets");
		set_paid(undefined);
	};

	const go_forward = async () => {
		const { first_name, last_name, billing_address, country, city, state, zip_code, email } = getBillingInfoValues();
		const { promo_code } = getPromoCodeValue();
		const { donation_amount, donation_type } = donation;

		if (!(user.first_name && user.last_name && user.email) && !(first_name && last_name && email)) {
			set_error_message("Please enter an email and name!");

			return;
		} else if (
			(paid || (donation.donation_amount > 0 && donation.donation_type !== "None")) &&
			(billing_address === "" || country === "" || city === "" || state === "" || zip_code === "")
		) {
			set_error_message("Please ensure that all relevant billing information is filled out!");

			return;
		} else if (!checks_passed || Object.keys(billingInfoErrors.errors).length > 0) {
			set_error_message("Please review your billing information and resolve any errors and try again!");

			return;
		} else if (error_message !== "") {
			set_error_message("");
		}

		let stripe_token: any = undefined;

		if (paid || (donation.donation_amount > 0 && donation.donation_type !== "None")) {
			if (!stripe || !elements) {
				set_error_message("An unknown error occurred! Please try again later!");

				return;
			}

			const card_number_element = await elements.getElement(CardNumberElement);
			const data: Data = {
				currency: "usd",
				name: user ? user?.first_name + " " + user?.last_name : first_name + " " + last_name,
				address_line1: billing_address,
				address_city: city,
				address_state: state,
				address_zip: zip_code,
				address_country: country,
			};

			if (!card_number_element) {
				set_error_message("An unknown error occurred! Please try again later!");
				return;
			}

			const result = await stripe.createToken(card_number_element, data);
			if (result.error) {
				set_error_message(result.error.message ? result.error.message : "An unknown error occurred!");
				return;
			} else {
				stripe_token = result.token;
			}
		}

		set_loading(true);
		clearBillingInfoErrors();

		let charge_cost: number = 0;
		const tickets_for_checkout: Array<TicketsForCheckout> | undefined = selected_tickets?.map(({ ticket, quantity }) => {
			const price: number = Number(ticket.price.$numberDecimal);

			if (price > 0) {
				const total_cost = compute_total_cost(price, quantity);

				charge_cost += price * quantity;

				return {
					ticket: ticket,
					quantity: quantity,
					total_cost: total_cost,
				};
			} else {
				return {
					ticket: ticket,
					quantity: quantity,
					total_cost: "0",
				};
			}
		});
		const payload: Payload = {
			orders: selected_tickets?.map((order) => {
				return {
					id: order.ticket._id,
					quantity: order.quantity,
				};
			}),
			organization: selected_organization,
			stripe_token: stripe_token,
			email: user?.email ?? email,
			event: selected_event,
			user: {
				user_id: user?._id ?? undefined,
				first_name: user?.first_name ?? first_name,
				last_name: user?.last_name ?? last_name,
			},
			promo_code: promo_code ?? undefined,
			donation:
				donation_amount && donation_type !== "None" && selected_event?.donation_type
					? {
							donation_amount: donation_amount,
							donation_type: selected_event.donation_type,
					  }
					: undefined,
			paid_tickets: paid,
			opted_in: opt_in,
		};

		api.post("/buy_tickets", payload)
			.then(({ data }) => {
				if (data?.message === "Successfully purchased tickets!") {
					set_tickets_for_confirmation(tickets_for_checkout);
					set_paid(undefined);
					set_checkout_donation({
						donation_amount: selected_event?.donation_type === "flat" ? "$" + donation_amount : donation_amount + "%",
						donation_cost:
							selected_event?.donation_type === "flat"
								? donation_amount
								: Number(charge_cost * (donation_amount / 100)),
					});
					resetBillingInfo();
					set_loading(false);
					navigate("/events/confirmation");
				} else if (data?.message === "Failed to purchase tickets.") {
					if (data.error.raw.message) {
						set_error_message(data.error.raw.message);
					}

					set_purchase_failure(true);
				} else {
					set_error_message("An unknown error occurred! Please try again later!");
				}

				set_loading(false);
			})
			.catch((err) => {
				set_loading(false);
				if (typeof err !== "string") {
					if (err?.response?.data?.error?.raw?.message) {
						set_error_message(err.response.data.error.raw.message);
					} else if (err?.response?.data?.message) {
						set_error_message(err.response.data.message);
					} else {
						set_error_message(err.toString());
					}
				} else {
					set_error_message(err.toString());
				}
				console.error(err);
				set_purchase_failure(true);
				set_loading(false);
			});
	};

	const submit_promo_code = async () => {
		if (confirm_promo_code.discount === 0 && confirm_promo_code.promo_code === "") {
			const promo = getPromoCodeValue("promo_code");

			api.post("/verify_promo_code", {
				event_id: selected_event?._id,
				promo_code: promo,
			})
				.then(({ data }) => {
					if (data.valid) {
						const discount = data.promo_doc.discount;

						const discounted_tickets = selected_tickets?.map(({ ticket, quantity }) => {
							return {
								ticket: {
									...ticket,
									price: {
										$numberDecimal: (
											Number(ticket.price.$numberDecimal) -
											Number(ticket.price.$numberDecimal) * discount
										).toString(),
									},
								},
								quantity: quantity,
							};
						});

						set_selected_tickets(discounted_tickets);
						set_confirm_promo_code({
							promo_code: promo,
							discount: discount,
						});
						set_promo_discount(discount);
					} else {
						set_warn({
							...warn,
							promo_code: true,
						});
					}
				})
				.catch((err) => {
					console.error(err);

					set_warn({
						...warn,
						promo_code: true,
					});
				});
		} else {
			set_warn({
				...warn,
				promo_code: true,
			});
		}
	};

	const validate_inputs = () => {
		const { first_name, last_name, email } = getBillingInfoValues();

		if ((user.first_name && user.last_name && user.email) || (first_name && last_name && email)) {
			const { donation_amount, donation_type } = donation;
			set_checks_passed(
				paid ||
					(!paid && donation_amount > 0 && donation_type !== "None") ||
					(!paid && donation_amount === 0 && donation_type === "None")
			);
		} else {
			set_checks_passed(false);
		}
	};

	const DonationSymbol = ({ type }: { type: string | undefined }) => {
		switch (type) {
			case DonationType.Flat: {
				return <span className="absolute left-[5px] top-[6px]">$</span>;
			}
			case DonationType.Percentage: {
				return <span className="absolute left-[45px] top-[6px]">%</span>;
			}
			default: {
				return null;
			}
		}
	};

	useEffect(() => {
		console.log(opt_in);
	}, [opt_in]);

	useEffect(() => {
		if (!stripe || !elements) {
			set_loading(true);
		} else set_loading(false);
	}, [stripe, elements]);

	if (!selected_tickets) {
		return <ErrorCode400 />;
	}

	function Buttons() {
		return (
			<div className="mt-4 flex w-full flex-row items-center justify-between md:w-[460px]">
				<SmallButton
					default_color={primary_color}
					hover_color={secondary_color}
					active_color={primary_color}
					onClick={go_back}
				>
					Back
				</SmallButton>
				<SmallButton
					default_color={primary_color}
					hover_color={secondary_color}
					active_color={primary_color}
					type={ButtonTypes.Submit}
					onClick={go_forward}
				>
					Submit
				</SmallButton>
			</div>
		);
	}

	return (
		<div className="mx-auto flex w-11/12 flex-col justify-between font-custom text-regular font-normal md:my-8 md:flex-row">
			{loading && (
				<div className="absolute z-[100] flex h-full w-full flex-col items-center justify-center bg-white">
					<Loader className={""} />
				</div>
			)}
			<form className="w-full md:w-3/5" onChange={handleBillingInfoSubmission(validate_inputs)}>
				<h1 className="mb-4 pt-4 text-center text-3xl font-semibold md:text-left">Billing Information</h1>
				{!paid && !donation.donation_amount && donation.donation_type === "None" && (
					<p className="mb-4 text-center text-xl md:text-left">
						The tickets you've selected are free! No billing information is required!
					</p>
				)}
				{paid || (!paid && donation.donation_amount > 0 && donation.donation_type !== "None") ? (
					<div className="w-[98%]">
						<div className={styles.billing_information}>
							<div
								className={styles.personal_billing_information}
								style={{
									width: "46%",
								}}
							>
								{!user?.email && (
									<div className={"my-2"}>
										<p>Email Address</p>
										<InputBox
											{...registerBillingInfo("email", {
												pattern: {
													value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i,
													message: "Please enter a valid email address",
												},
												maxLength: { value: 330, message: "Please enter a valid email address!" }, // max length gmail address
												minLength: { value: 4, message: "Please enter a valid email address!" },
												validate: {
													value: (value: string) =>
														value === getBillingInfoValues("confirm_email") ||
														"Please ensure that both emails match each other!",
												},
											})}
											onChange={(value: string) => {
												if (purchase_failure) {
													if (error_message !== "") {
														set_error_message("");
													}

													set_purchase_failure(false);
												}

												set_opt_in(
													selected_organization?.mailing_list
														? selected_organization?.mailing_list?.includes(value)
														: false
												);

												setBillingInfoValue("email", value);
											}}
											default_color={primary_color}
											hover_color={secondary_color}
											focus_color={secondary_color}
										/>
										{billingInfoErrors.errors?.email?.message && (
											<ErrorMessage message={billingInfoErrors.errors.email.message} />
										)}
									</div>
								)}
								<div className={"my-2"}>
									<p>Card Number</p>
									<div className={input_style}>
										<CardNumberElement
											onChange={(event: any) => {
												if (event.error) {
													set_billing_info_errors({
														...billing_info_errors,
														number: event.error.message,
													});
												} else {
													set_billing_info_errors({
														...billing_info_errors,
														number: "",
													});
												}
											}}
											options={{ style: font_style }}
											className={"inset-0 w-full"}
										/>
									</div>
									{billing_info_errors.number && <ErrorMessage message={billing_info_errors.number} />}
								</div>
								<div className={"my-2"}>
									<p>Expiration Date</p>
									<div className={input_style}>
										<CardExpiryElement
											onChange={(event: any) => {
												if (event.error) {
													set_billing_info_errors({
														...billing_info_errors,
														exp: event.error.message,
													});
												} else {
													set_billing_info_errors({
														...billing_info_errors,
														exp: "",
													});
												}
											}}
											options={{ style: font_style }}
											className={"inset-0 w-full"}
										/>
									</div>
									{billing_info_errors.exp && <ErrorMessage message={billing_info_errors.exp} />}
								</div>
								<div className={"my-2"}>
									<p>Security Code</p>
									<div className={input_style}>
										<CardCvcElement
											onChange={(event: any) => {
												if (event.error) {
													set_billing_info_errors({
														...billing_info_errors,
														cvc: event.error.message,
													});
												} else {
													set_billing_info_errors({
														...billing_info_errors,
														cvc: "",
													});
												}
											}}
											options={{ style: font_style }}
											className={"inset-0 w-full"}
										/>
									</div>
									{billing_info_errors.cvc && <ErrorMessage message={billing_info_errors.cvc} />}
								</div>
								<div className={styles.billing_information_row}>
									{!user?.first_name && (
										<div className={"my-2"}>
											<p>First Name</p>
											<InputBox
												{...registerBillingInfo("first_name", {
													maxLength: { value: 330, message: "Please enter a valid first name!" },
													minLength: { value: 1, message: "Please enter a valid first name!" },
												})}
												onChange={(value: string) => {
													if (purchase_failure) {
														if (error_message !== "") {
															set_error_message("");
														}

														set_purchase_failure(false);
													}

													setBillingInfoValue("first_name", value);
												}}
												default_color={primary_color}
												hover_color={secondary_color}
												focus_color={secondary_color}
											/>
											{billingInfoErrors.errors?.first_name?.message && (
												<ErrorMessage message={billingInfoErrors.errors.first_name.message} />
											)}
										</div>
									)}
									{!user?.last_name && (
										<div className={"my-2"}>
											<p>Last Name</p>
											<InputBox
												{...registerBillingInfo("last_name", {
													maxLength: { value: 330, message: "Please enter a valid last name!" },
													minLength: { value: 1, message: "Please enter a valid last name!" },
												})}
												onChange={(value: string) => {
													if (purchase_failure) {
														if (error_message !== "") {
															set_error_message("");
														}

														set_purchase_failure(false);
													}

													setBillingInfoValue("last_name", value);
												}}
												default_color={primary_color}
												hover_color={secondary_color}
												focus_color={secondary_color}
											/>
											{billingInfoErrors.errors?.last_name?.message && (
												<ErrorMessage message={billingInfoErrors.errors.last_name.message} />
											)}
										</div>
									)}
								</div>
							</div>
							<div
								className={styles.personal_billing_information}
								style={{
									width: "46%",
								}}
							>
								{!user?.email && (
									<div className={"my-2"}>
										<p>Confirm Email Address</p>
										<InputBox
											{...registerBillingInfo("confirm_email", {
												pattern: {
													value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i,
													message: "Please enter a valid email address",
												},
												maxLength: { value: 330, message: "Please enter a valid email address!" },
												minLength: { value: 4, message: "Please enter a valid email address!" },
												validate: {
													value: (value: string) =>
														value === getBillingInfoValues("email") ||
														"Please ensure that both emails match each other!",
												},
											})}
											onChange={(value: string) => {
												if (purchase_failure) {
													if (error_message !== "") {
														set_error_message("");
													}

													set_purchase_failure(false);
												}

												setBillingInfoValue("confirm_email", value);
											}}
											default_color={primary_color}
											hover_color={secondary_color}
											focus_color={secondary_color}
										/>
										{billingInfoErrors.errors?.confirm_email?.message && (
											<ErrorMessage message={billingInfoErrors.errors.confirm_email.message} />
										)}
									</div>
								)}
								<div className={"my-2"}>
									<p>Billing Address</p>
									<InputBox
										{...registerBillingInfo("billing_address", {
											minLength: { value: 1, message: "Please enter a valid email address!" },
										})}
										placeholder={"e.g. 1234 First St"}
										onChange={(value: string) => {
											if (purchase_failure) {
												if (error_message !== "") {
													set_error_message("");
												}

												set_purchase_failure(false);
											}

											setBillingInfoValue("billing_address", value);
										}}
										default_color={primary_color}
										hover_color={secondary_color}
										focus_color={secondary_color}
										autocomplete="street-address"
									/>
									{billingInfoErrors.errors?.billing_address?.message && (
										<ErrorMessage message={billingInfoErrors.errors.billing_address.message} />
									)}
								</div>
								<div className={"my-2"}>
									<p>Country</p>
									<InputBox
										{...registerBillingInfo("country", {
											minLength: { value: 1, message: "Please enter a valid country!" },
										})}
										placeholder={"e.g. United States"}
										onChange={(value: string) => {
											if (purchase_failure) {
												if (error_message !== "") {
													set_error_message("");
												}

												set_purchase_failure(false);
											}

											setBillingInfoValue("country", value);
										}}
										default_color={primary_color}
										hover_color={secondary_color}
										focus_color={secondary_color}
										autocomplete="country"
									/>
									{billingInfoErrors.errors?.country?.message && (
										<ErrorMessage message={billingInfoErrors.errors.country.message} />
									)}
								</div>
								<div className={"my-2"}>
									<p>State</p>
									<InputBox
										{...registerBillingInfo("state", {
											maxLength: { value: 2, message: "Please enter a valid state!" },
											minLength: { value: 2, message: "Please enter a valid state!" },
										})}
										placeholder={"e.g. CO"}
										onChange={(value: string) => {
											if (purchase_failure) {
												if (error_message !== "") {
													set_error_message("");
												}

												set_purchase_failure(false);
											}

											setBillingInfoValue("state", value);
										}}
										maxLength={2}
										default_color={primary_color}
										hover_color={secondary_color}
										focus_color={secondary_color}
										autocomplete="address-level1"
										name="state"
									/>
									{billingInfoErrors.errors?.state?.message && (
										<ErrorMessage message={billingInfoErrors.errors.state.message} />
									)}
								</div>
								<div className={"my-2"}>
									<p>City</p>
									<InputBox
										{...registerBillingInfo("city", {
											minLength: { value: 1, message: "Please enter a valid city!" },
										})}
										onChange={(value: string) => {
											if (purchase_failure) {
												if (error_message !== "") {
													set_error_message("");
												}

												set_purchase_failure(false);
											}

											setBillingInfoValue("city", value);
										}}
										default_color={primary_color}
										hover_color={secondary_color}
										focus_color={secondary_color}
										autocomplete="address-level2"
										name="city"
									/>
									{billingInfoErrors.errors?.city?.message && (
										<ErrorMessage message={billingInfoErrors.errors.city.message} />
									)}
								</div>
								<div className={"my-2"}>
									<p>Postal Code</p>
									<InputBox
										{...registerBillingInfo("zip_code", {
											maxLength: { value: 5, message: "Please enter a valid postal code!" },
											minLength: { value: 5, message: "Please enter a valid postal code!" },
										})}
										onChange={(value: string) => {
											if (purchase_failure) {
												if (error_message !== "") {
													set_error_message("");
												}

												set_purchase_failure(false);
											}

											if (value.length > 5) {
												value = value.slice(0, 5);
											}

											value = value.replace(/[^\d]/g, "");

											setBillingInfoValue("zip_code", value);
										}}
										maxLength={5}
										default_color={primary_color}
										hover_color={secondary_color}
										focus_color={secondary_color}
										autocomplete="postal-code"
									/>
									{billingInfoErrors.errors?.zip_code?.message && (
										<ErrorMessage message={billingInfoErrors.errors.zip_code.message} />
									)}
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="flex w-[98%] flex-row items-start justify-between">
						<div className="m-0 flex w-[46%] flex-col items-start">
							{!user?.first_name && (
								<div className={"my-2 w-full"}>
									<p>First Name</p>
									<InputBox
										{...registerBillingInfo("first_name", {
											maxLength: { value: 330, message: "Please enter a valid first name!" },
											minLength: { value: 1, message: "Please enter a valid first name!" },
										})}
										onChange={(value: string) => {
											if (purchase_failure) {
												if (error_message !== "") {
													set_error_message("");
												}

												set_purchase_failure(false);
											}

											setBillingInfoValue("first_name", value);
										}}
										default_color={primary_color}
										hover_color={secondary_color}
										focus_color={secondary_color}
									/>
									{billingInfoErrors.errors?.first_name?.message && (
										<ErrorMessage message={billingInfoErrors.errors.first_name.message} />
									)}
								</div>
							)}
							{!user?.email && (
								<div className={"my-2 w-full"}>
									<p>Email Address</p>
									<InputBox
										{...registerBillingInfo("email", {
											pattern: {
												value: /^\s*$|^[^@.]+$|\.(com|net|org|gov|fun|edu|gg)$/i,
												message: "Please enter a valid email address",
											},
											maxLength: { value: 330, message: "Please enter a valid email address!" }, // max length gmail address
											minLength: { value: 4, message: "Please enter a valid email address!" },
											validate: {
												value: (value: string) =>
													value === getBillingInfoValues("confirm_email") ||
													"Please ensure that both emails match each other!",
											},
										})}
										onChange={(value: string) => {
											if (purchase_failure) {
												if (error_message !== "") {
													set_error_message("");
												}

												set_purchase_failure(false);
											}

											set_opt_in(
												selected_organization?.mailing_list
													? selected_organization?.mailing_list?.includes(value)
													: false
											);

											setBillingInfoValue("email", value);
										}}
										default_color={primary_color}
										hover_color={secondary_color}
										focus_color={secondary_color}
									/>
									{billingInfoErrors.errors?.email?.message && (
										<ErrorMessage message={billingInfoErrors.errors.email.message} />
									)}
								</div>
							)}
						</div>
						<div className="m-0 flex w-[46%] flex-col items-start">
							{!user?.last_name && (
								<div className={"my-2 w-full"}>
									<p>Last Name</p>
									<InputBox
										{...registerBillingInfo("last_name", {
											maxLength: { value: 330, message: "Please enter a valid last name!" },
											minLength: { value: 1, message: "Please enter a valid last name!" },
										})}
										onChange={(value: string) => {
											if (purchase_failure) {
												if (error_message !== "") {
													set_error_message("");
												}

												set_purchase_failure(false);
											}

											setBillingInfoValue("last_name", value);
										}}
										default_color={primary_color}
										hover_color={secondary_color}
										focus_color={secondary_color}
									/>
									{billingInfoErrors.errors?.last_name?.message && (
										<ErrorMessage message={billingInfoErrors.errors.last_name.message} />
									)}
								</div>
							)}
							{!user?.email && (
								<div className={"my-2 w-full"}>
									<p>Confirm Email Address</p>
									<InputBox
										{...registerBillingInfo("confirm_email", {
											pattern: {
												value: /^\s*$|^[^@.]+$|\.(com|net|org|gov|fun|edu|gg)$/i,
												message: "Please enter a valid email address",
											},
											maxLength: { value: 330, message: "Please enter a valid email address!" },
											minLength: { value: 4, message: "Please enter a valid email address!" },
											validate: {
												value: (value: string) =>
													value === getBillingInfoValues("email") ||
													"Please ensure that both emails match each other!",
											},
										})}
										onChange={(value: string) => {
											if (purchase_failure) {
												if (error_message !== "") {
													set_error_message("");
												}

												set_purchase_failure(false);
											}

											setBillingInfoValue("confirm_email", value);
										}}
										default_color={primary_color}
										hover_color={secondary_color}
										focus_color={secondary_color}
									/>
									{billingInfoErrors.errors?.confirm_email?.message && (
										<ErrorMessage message={billingInfoErrors.errors.confirm_email.message} />
									)}
								</div>
							)}
						</div>
					</div>
				)}
				{(purchase_failure || error_message) && (
					<ErrorMessage
						className={"w-full whitespace-normal font-custom text-regular font-bold text-fangarde-medium-red "}
						message={`There was an issue confirming your purchase. ${error_message}`}
					/>
				)}
			</form>
			<div className="flex flex-col items-center rounded bg-fangarde-light-gray px-8 py-4 md:min-h-[480px] md:w-2/5">
				<BillingSummaryBox
					selected_tickets={selected_tickets}
					donation_amount={donation.donation_amount}
					donation_type={
						selected_event?.donation_type === DonationType.Flat ||
						selected_event?.donation_type === DonationType.Percentage
							? selected_event?.donation_type
							: undefined
					}
					promo_discount={confirm_promo_code.discount > 0 ? confirm_promo_code.discount : undefined}
				/>
				<div className="w-full">
					<h3 className={styles.promo_code_donations_header}>Promo Code</h3>
					<form className={"flex w-full flex-row items-center"} onSubmit={handlePromoCodeSubmission(submit_promo_code)}>
						<div className="w-9/12">
							<InputBox
								{...registerPromoCode("promo_code", {
									minLength: { value: 1, message: "Please enter a promo code!" },
								})}
								placeholder={disallow_promo ? "No promo code allowed for this event!" : "Get your discount now!"}
								disabled={
									(confirm_promo_code.discount > 0 && confirm_promo_code.promo_code !== "") || disallow_promo
								}
								onChange={(value: string) => {
									if (warn.promo_code) {
										if (error_message !== "") {
											set_error_message("");
										}

										set_warn({
											...warn,
											promo_code: false,
										});
									}

									setPromoCodeValue("promo_code", value);
								}}
								maxLength={25}
								default_color={primary_color}
								hover_color={secondary_color}
								focus_color={secondary_color}
							/>
							{promoCodeErrors.errors.promo_code && (
								<ErrorMessage message={promoCodeErrors.errors.promo_code.toString()} />
							)}
						</div>
						<SmallButton
							className="h-9 w-36 rounded-md border-[3px] bg-transparent font-custom text-regular "
							disabled={(confirm_promo_code.discount > 0 && confirm_promo_code.promo_code !== "") || disallow_promo}
							default_color={primary_color}
							hover_color={secondary_color}
							active_color={primary_color}
						>
							Apply
						</SmallButton>
					</form>
					{warn.promo_code && <ErrorMessage message={"Promo Code does not exist!"} />}
					{confirm_promo_code.discount > 0 && confirm_promo_code.promo_code !== "" && (
						<ErrorMessage
							className={`
							w-full 
							whitespace-nowrap 
							font-custom 
							text-regular 
							font-bold 
							text-green-500 
						`}
							message={"Successfully applied promo code!"}
						/>
					)}
				</div>
				{selected_event?.donations ? (
					<div className={styles.donations_wrapper}>
						<p className={styles.donation_message_section}>{selected_event?.donation_message}</p>
						<h3 className={styles.promo_code_donations_header}>Donation</h3>
						<div className={styles.donations_selectable_column}>
							<div className="mb-6 w-full">
								<Dropdown
									options={["None", ...selected_event.donation_options, "Custom"]}
									value={donation.donation_type === "Custom" ? "Custom" : donation.donation_amount}
									onChange={(value: string) => {
										if (warn.donation) {
											set_warn({
												...warn,
												donation: false,
											});
										}

										if (value !== "None" && value !== "Custom") {
											set_donation({
												donation_amount: Number(value),
												donation_type: "Standard",
											});
										} else {
											// if the donation is custom, then we need to set one field and display an input box for the custom amount
											set_donation({
												donation_amount: 0,
												donation_type: value,
											});
										}
									}}
									renderOption={(option: any, index: number) => {
										if (option === "None" || option === "Custom") {
											return (
												<option value={option} key={index}>
													{option}
												</option>
											);
										}

										return (
											<option value={option} key={index}>
												{selected_event.donation_type === "flat" && "$"}
												{option}
												{selected_event.donation_type === "percentage" && "%"}
											</option>
										);
									}}
									default_color={primary_color}
									hover_color={secondary_color}
									focus_color={secondary_color}
								/>
							</div>
							{donation.donation_type === "Custom" && (
								<div className={"relative w-full"}>
									<DonationSymbol type={selected_event.donation_type} />
									<InputBox
										value={donation.donation_amount}
										placeholder={"Enter a custom donation amount."}
										style={selected_event.donation_type === DonationType.Flat && { paddingLeft: "14px" }}
										onChange={(value: string) => {
											if (warn.donation) {
												set_warn({
													...warn,
													donation: false,
												});
											}

											const cleaned_value = value.replace(/\D/g, "");

											if (Number(cleaned_value) > 0) {
												set_donation({
													...donation,
													donation_amount: Number(cleaned_value),
												});
											} else {
												set_donation({
													...donation,
													donation_amount: 0,
												});

												set_warn({
													...warn,
													donation: true,
												});
											}
										}}
										maxLength={4}
										default_color={primary_color}
										hover_color={secondary_color}
										focus_color={secondary_color}
									/>
								</div>
							)}
							{warn.donation && <ErrorMessage message={"Please enter a number greater than 0!"} />}
						</div>
					</div>
				) : (
					<div className={"h-[250px]"}></div>
				)}
				<div className="flex w-full flex-col items-start md:w-[460px]">
					{selected_organization?.request_marketing_communications?.requested && (
						<CheckBox
							checked={opt_in}
							label={
								selected_organization?.request_marketing_communications?.custom_message === "" ||
								!selected_organization?.request_marketing_communications?.custom_message
									? `I would like to receive email marketing communications from ${selected_organization.name}!`
									: selected_organization?.request_marketing_communications?.custom_message
							}
							onClick={() => set_opt_in(!opt_in)}
							default_color={primary_color}
							hover_color={secondary_color}
							active_color={primary_color}
						/>
					)}
					<Buttons />
				</div>
			</div>
		</div>
	);
};

export default BillingPage;
