import styles from "./loader.module.css";

interface LoaderProps {
	className?: string;
}

const Loader = ({ className }: LoaderProps) => {
	return (
		<div className={className ?? styles.root}>
			<div className={styles.loader}></div>
		</div>
	);
};

export default Loader;
