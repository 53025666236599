import { useNavigate, useLocation } from "react-router-dom";

const AccountingMenu = () => {
	const navigate = useNavigate();
	let location = useLocation();

	const default_link: string =
		"text-black text-regular text-center font-bold decoration-none hover:cursor-pointer hover:bg-fangarde-light-gray hover:rounded-md hover:text-black h-[30px] w-[50%] pt-[4px]";
	const active_link: string =
		"text-white text-regular font-bold decoration-none text-center bg-medium-blue rounded-md h-[30px] w-[50%] pt-[4px]";

	return (
		<div className={"mb-[40px] flex flex-row items-center rounded-lg border-2 border-medium-blue"}>
			<div
				className={location.pathname === "/account/accounting/books" ? active_link : default_link}
				onClick={() => navigate("/account/accounting/books")}
			>
				<p className={"font-custom text-regular font-bold"}>{"Books"}</p>
			</div>
			<div
				className={location.pathname === "/account/accounting/banking" ? active_link : default_link}
				onClick={() => navigate("/account/accounting/banking")}
			>
				<p className={"font-custom text-regular font-bold"}>{"Banking"}</p>
			</div>
		</div>
	);
};

export default AccountingMenu;
