import { useState, useRef, useEffect } from "react";
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";

import ErrorMessage from "components/error_message";
import InputBox from "components/input_box";
import SmallButton from "components/small_button/small_button";

import styles from "./index.module.css";
import common_style from "../../../../../../../../common/css/common.module.css";

import { font_style, input_style } from "common/styles/stripe/stripe_styles";

import color_palette from "../../../../../../../../common/types/colors";

type Warning = {
	first_name: boolean;
	last_name: boolean;
	card_number: string;
	expiration_date: string;
	security_code: string;
	middle_initial?: boolean;
};

const empty_user_information: UserInformation = {
	first_name: "",
	middle_initial: "",
	last_name: "",
};

const empty_billing_information: BillingInformation = {
	card_number: "",
	expiration_date: "",
	security_code: "",
	billing_address: "",
	country: "",
	city: "",
	state: "",
	zip_code: "",
};
const empty_warning = {
	first_name: false,
	last_name: false,
	middle_initial: false,
	card_number: "",
	expiration_date: "",
	security_code: "",
};

interface CardPointOfSaleFormProps {
	onSubmit: (stripe_token: any, user_information: UserInformation) => void;
	disabled?: boolean;
	error: string;
	set_error: Function;
	purchase_failure: boolean;
	set_purchase_failure: Function;
	reset: boolean;
	set_reset: Function;
}
const CardPointOfSaleForm = ({
	onSubmit,
	disabled,
	error,
	set_error,
	purchase_failure,
	set_purchase_failure,
	reset,
	set_reset,
}: CardPointOfSaleFormProps) => {
	const [billing_information, set_billing_information] = useState<BillingInformation>(empty_billing_information);
	const [user_information, set_user_information] = useState<UserInformation>(empty_user_information);
	const [warning, set_warning] = useState<Warning>(empty_warning);
	const stripe = useStripe();
	const elements = useElements();
	const set_billing_information_and_warnings = (new_billing_information: BillingInformation) => {
		set_billing_information(new_billing_information);
		set_warning({
			...warning,
		});
	};
	const set_user_information_and_warnings = (new_user_information: UserInformation) => {
		set_user_information(new_user_information);
		set_warning({
			...warning,
			first_name: new_user_information.first_name.length < 1,
			last_name: new_user_information.last_name.length < 1,
		});
	};
	//Handle Credit Card Swipe
	const first_name_ref = useRef<HTMLInputElement>(null);

	const middle_initial_ref = useRef<HTMLInputElement>(null);
	const last_name_ref = useRef<HTMLInputElement>(null);

	const handleKeyDown = (event: any) => {
		if (event.key === "Enter") {
			//Get Card Swipe Data
			const cardData = event.target.value ? event.target.value : "";
			//Check if is card swipe
			if (cardData.slice(0, 1) === "%") {
				// const cardData = swipeInputRef.current!.value;
				// Parsing the card data based on the provided format
				const [track1Data, ,] = cardData.split("?");
				// Extracting the name, card numbe r, expiry date, and security code
				const [cardNumberData, cardHolderName, additionalData] = track1Data.split("^");
				const [cardHolderLastName, cardHolderFirstName] = cardHolderName.split("/");

				// Get CVC1, kind of useless since i think it cannot be used for online transactions
				//IDK enough about payments but I'll leave this in in case it can be used.
				const expiryData = additionalData.slice(0, 4);
				const MM = expiryData.slice(2, 4);
				const YY = expiryData.slice(0, 2);
				const expiryDate = `${MM}${YY}`;

				const cardNumber = cardNumberData.slice(2);
				// Updating the input fields with the extracted card information
				set_user_information({
					...user_information,
					first_name: cardHolderFirstName,
					last_name: cardHolderLastName,
				});
				set_billing_information({
					...billing_information,
					card_number: cardNumber,
					expiration_date: expiryDate,
				});
				set_warning({
					...warning,
					first_name: cardHolderFirstName.length < 1,
					last_name: cardHolderLastName.length < 1,
				});
			}
		}
	};

	//We might need this later
	// const simulateCardSwipe = () => {
	//     const cardData =  "%B4242424242424242^ANTONIEWICZ/BRAD^2403101000000001000000003000000?;1234567890123456=1103101000000300001?";
	//     swipeInputRef.current!.value = cardData;
	//     const event = new KeyboardEvent('keydown', {
	//             key: 'Enter',
	//             bubbles: true,
	//             cancelable: true
	//     });
	//     swipeInputRef.current!.dispatchEvent(event);
	// };

	const isFormFilled =
		!warning.first_name && !warning.last_name && user_information.first_name.length > 0 && user_information.last_name.length > 0;

	const onClick = async () => {
		//Check if all fields are filled
		set_error("");
		if (isFormFilled) {
			if (!stripe || !elements) {
				set_error("Stripe.js has not loaded yet. Make sure to disable any ad blockers.");
				return; // Stripe.js or Elements has not loaded yet
			}
			const cardNumberElement = elements.getElement(CardNumberElement);
			if (cardNumberElement) {
				const result = await stripe.createToken(cardNumberElement);
				if (result.error) {
					set_error(result.error.message ? result.error.message : "An unknown error occurred.");
					return;
				} else {
					const stripe_token = result.token;
					//Send data to backend
					onSubmit(stripe_token, user_information);
				}
			}
		} else {
			set_error("Please fill out all fields");
		}
	};

	useEffect(() => {
		if (reset) {
			set_billing_information(empty_billing_information);
			set_user_information(empty_user_information);
			set_warning(empty_warning);
			set_reset(false);
		}
	}, [reset]);
	useEffect(() => {
		const handle_key_press = (event: any) => {
			if (document.activeElement?.tagName.toLowerCase() !== "input") {
				first_name_ref.current?.focus();
			}
		};
		window.addEventListener("keydown", handle_key_press);

		// Don't forget to cleanup the event listener on component unmount
		return () => {
			window.removeEventListener("keydown", handle_key_press);
		};
	}, []);
	return (
		<div className={styles.personal_billing_information}>
			<div className={styles.billing_information_row}>
				<div className={styles.input_container}>
					<p>First Name</p>
					<InputBox
						ref={first_name_ref}
						placeholder={""}
						value={user_information.first_name}
						onChange={(value: string) => {
							if (purchase_failure) {
								if (error !== "") {
									set_error("");
								}
								set_purchase_failure(false);
							}
							if (value[value.length - 1] === " " && value[value.length - 2] !== " ") {
								middle_initial_ref.current?.focus();
							} else {
								set_user_information_and_warnings({
									...user_information,
									first_name: value,
								});
							}
						}}
					/>
					{warning.first_name && (
						<p className={`${styles.error_message} ${common_style.common_bold}`} style={{ color: color_palette.red }}>
							Please enter a valid first name.
						</p>
					)}
				</div>
				<div className={styles.middle_input_container}>
					<p>MI.</p>
					<InputBox
						placeholder={""}
						value={user_information.middle_initial ? user_information.middle_initial : ""}
						onChange={(value: string) => {
							if (purchase_failure) {
								if (error !== "") {
									set_error("");
								}
								set_purchase_failure(false);
							}
							if (value[value.length - 1] === " ") {
								last_name_ref.current?.focus();
							} else {
								set_user_information_and_warnings({
									...user_information,
									middle_initial: value,
								});
							}
						}}
					/>
				</div>
				<div className={styles.input_container}>
					<p>Last Name</p>
					<InputBox
						placeholder={""}
						value={user_information.last_name}
						onChange={(value: string) => {
							if (purchase_failure) {
								if (error !== "") {
									set_error("");
								}
								set_purchase_failure(false);
							}
							if (value[value.length - 1] !== " ") {
								set_user_information_and_warnings({
									...user_information,
									last_name: value,
								});
							}
						}}
					/>
					{warning.last_name && (
						<p className={`${styles.error_message} ${common_style.common_bold}`} style={{ color: color_palette.red }}>
							Please enter a valid last name.
						</p>
					)}
				</div>
			</div>
			<div className={styles.billing_information_row}>
				<div className={styles.input_container} style={{ marginRight: "40px" }}>
					<p>Card Number</p>
					<div className={input_style}>
						<CardNumberElement
							onChange={(event) => {
								if (event.error) {
									set_warning({
										...warning,
										card_number: event.error.message,
									});
								} else {
									set_warning({
										...warning,
										card_number: "",
									});
									set_error("");
								}
							}}
							options={{ style: font_style }}
							className="inset-0 w-full"
						/>
					</div>
					{warning.card_number && <ErrorMessage message={warning.card_number} />}
				</div>
				<div className={styles.input_container}>
					<p>Expiration Date</p>
					<div className={input_style}>
						<CardExpiryElement
							onChange={(event) => {
								if (event.error) {
									set_warning({
										...warning,
										expiration_date: event.error.message,
									});
								} else {
									set_warning({
										...warning,
										expiration_date: "",
									});
									set_error("");
								}
							}}
							options={{ style: font_style }}
							className="inset-0 w-full"
						/>
					</div>
					{warning.expiration_date && <ErrorMessage message={warning.expiration_date} />}
				</div>
			</div>
			<div className={styles.security_code}>
				<p>Security Code</p>
				<div className={input_style}>
					<CardCvcElement
						onChange={(event) => {
							if (event.error) {
								set_warning({
									...warning,
									security_code: event.error.message,
								});
							} else {
								set_warning({
									...warning,
									security_code: "",
								});
								set_error("");
							}
						}}
						options={{ style: font_style }}
						className="inset-0 w-full"
					/>
				</div>
				{warning.security_code && <ErrorMessage message={warning.security_code} />}
			</div>
			<div className={styles.button}>
				<SmallButton disabled={disabled} onClick={() => onClick()}>
					Buy Tickets and Print
				</SmallButton>
				{error && <ErrorMessage message={error} />}
			</div>
		</div>
	);
};

export default CardPointOfSaleForm;
