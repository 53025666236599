import axios, { AxiosInstance } from "axios";
import config from "config/index";
import { get_current_jwt } from "util/user";

export const api: AxiosInstance = axios.create({
	baseURL: config.apiGateway,
	headers: {
		"Content-Type": "application/json",
	},
});

// Set the Authorization header interceptor
api.interceptors.request.use(
	(config) => {
		const token = get_current_jwt();
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);
