import { Routes, Route } from "react-router-dom";

import About from "pages/about/about";
import BillingPage from "pages/events/billing_page/billing_page";
import Contact from "pages/contact/contact";
import EventOverview from "pages/events/event_overview_page/event_overview";
import Home from "pages/events/home_page/home";
import OrderSummary from "pages/events/order_summary_page/order_summary_page";
import Tickets from "pages/events/tickets_page/tickets_page";
import UniqueOverview from "pages/events/unique_event";
import AccountRoutes from "routes/account_routes";
import NotFound from "components/error_displays/404_not_found";

const Router = () => {
	return (
		<div className={"mt-[120px] md:mt-[80px]"}>
			<Routes>
				<Route path="/account/*" element={<AccountRoutes />} />
				<Route path="/" element={<Home />} />
				<Route path="/about" element={<About />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="/events/:id" element={<UniqueOverview />} />
				<Route path="/events/billing_info" element={<BillingPage />} />
				<Route path="/events/buy_tickets" element={<Tickets />} />
				<Route path="/events/confirmation" element={<OrderSummary />} />
				<Route path="/events/event_overview" element={<EventOverview />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</div>
	);
};

export default Router;
