import Books from "../books_page/books";
import Banking from "../banking_page/banking";
import { AccountingItem } from "../../../../../../../../common";

// author: Jack Hau

interface Props {
	menuItem: AccountingItem;
}

const AccountingContent = ({ menuItem }: Props) => {
	const renderPage = (menuItem: AccountingItem) => {
		switch (menuItem) {
			case AccountingItem.books:
				return (
					<div>
						<Books />
					</div>
				);
			case AccountingItem.banking:
				return (
					<div>
						<Banking />
					</div>
				);
			default:
				return <div></div>;
		}
	};

	return renderPage(menuItem);
};

export default AccountingContent;
