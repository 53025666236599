import color_palette from "common/types/colors";

type Props = {
	className?: string;
	color?: string;
};

function CommonBarSmall({ className, color }: Props) {
	const bar_style: string =
		className ??
		`
			h-[3px] 
			w-[30px] 
			rotate-90 
			border-0 
		`;

	return <hr className={bar_style} style={{ backgroundColor: color ?? color_palette.medium_blue }} />;
}

export default CommonBarSmall;
