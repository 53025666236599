import { useState } from "react";

import Dropdown from "components/dropdown";
import ErrorMessage from "components/error_message";
import InputBox from "components/input_box";
import SmallButton from "../../../../../../../../../components/small_button/small_button";
import TextAreaInputBox from "components/text_area";

import styles from "./general_event_info.module.css";
import { GeneralInfoEventCreateErrors, EmptyGeneralInfoEventCreateErrors } from "../../../../../../types/types";
import { states } from "./constants";
import { convertToBase64 } from "../../../../../../../../../common/functions";
import { Genre } from "../../../../../../../../../common";

type GeneralEventInfoProps = {
	event: EventCreate;
	setEvent: Function;
	onContinue: Function;
	onBack: Function;
};

const GeneralEventInfo = ({ onBack, onContinue, setEvent, event }: GeneralEventInfoProps) => {
	const [bannerImage, setBannerImage] = useState<string>(event.banner ? event.banner : "");
	const [bannerErrorMsg, setBannerErrorMsg] = useState<string>("");
	const [searchImage, setSearchImage] = useState<string>(event.search_image ? event.search_image : "");
	const [searchImageError, setSearchImageError] = useState<string>("");
	const [displayErrors, setDisplayErrors] = useState<boolean>(false);
	const [errors, setErrors] = useState<GeneralInfoEventCreateErrors>(EmptyGeneralInfoEventCreateErrors);

	const { address } = event;
	const { street, zip, state, city } = address;
	const isFormComplete =
		event.name !== "" &&
		event.genre !== Genre.None &&
		event.location !== "" &&
		city !== "" &&
		street !== "" &&
		state !== "" &&
		zip !== "";
	const noErrorsPresent =
		errors.city === "" &&
		errors.genre === "" &&
		errors.name === "" &&
		errors.location === "" &&
		errors.street === "" &&
		errors.state === "" &&
		errors.zip === "";

	function validateZipCode(zipCode: string) {
		const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
		if (!zipCodeRegex.test(zipCode)) {
			setErrors({
				...errors,
				zip: "Please enter a valid zip code!",
			});
		} else {
			setErrors({
				...errors,
				zip: "",
			});
		}
	}
	function renderErrors(header: keyof EventCreate) {
		if (displayErrors && (event[header] === "" || event[header] === Genre.None)) {
			return <ErrorMessage message={"This field is required!"} />;
		} else {
			return <div className="h-[24px]"></div>; // hacky way to even out spacing
		}
	}
	function renderAddressErrors(header: keyof Address) {
		if (displayErrors && errors[header]) {
			return <ErrorMessage message={errors[header]} />;
		} else if (displayErrors && address[header] === "") {
			return <ErrorMessage message={"This field is required!"} />;
		} else {
			return <div className="h-[24px]"></div>; // hacky way to even out spacing
		}
	}
	async function handleImageUpload(e: any, setImage: Function, setError: Function, header: keyof EventCreate) {
		const selectedFile = e.target.files[0];
		const MAX_FILE_SIZE = 7168;

		const fileSizeKiloBytes = selectedFile.size / 1024;
		if (fileSizeKiloBytes > MAX_FILE_SIZE) {
			setError("File size is greater than maximum limit: 7MB");
			return;
		} else {
			setError("");
			setImage(await convertToBase64(selectedFile));
			setEvent({
				...event,
				[header]: await convertToBase64(selectedFile),
			});
		}
	}
	function handleChange(value: any, header: keyof EventCreate, subHeader?: keyof Address, prev?: any) {
		let newEvent: EventCreate;
		if (!subHeader) {
			newEvent = {
				...event,
				[header]: value,
			};
		} else {
			newEvent = {
				...event,
				[header]: {
					...prev,
					[subHeader]: value,
				},
			};
		}
		setEvent(newEvent);
	}
	function handleContinue() {
		if (isFormComplete && noErrorsPresent) {
			onContinue();
		} else {
			setDisplayErrors(true);
		}
	}
	return (
		<div className="mt-4 flex flex-col items-center">
			<div className={styles.column}>
				<h1>General Event Information</h1>
				<div className={styles.userInputRow}>
					<div className={styles.userInputColumn}>
						<h2 className={styles.inputTitle}>Event General Information</h2>
						<div className={styles.userInputRow}>
							<div className={styles.inputContainer}>
								<h3>Event Name:</h3>
								<InputBox
									value={event.name}
									maxLength={250}
									onChange={(value: string) => handleChange(value, "name")}
								/>
								{renderErrors("name")}
							</div>
							<div className={styles.inputContainer}>
								<h3>Event Type:</h3>
								<Dropdown
									options={Object.values(Genre)}
									value={event.genre}
									renderOption={(option: any, index: number) => {
										return (
											<option key={index} value={option}>
												{option}
											</option>
										);
									}}
									onChange={(value: string) => handleChange(value, "genre")}
								/>
								{renderErrors("genre")}
							</div>
						</div>
						<div className={"mb-[30px] flex w-full flex-col items-start"}>
							<h3>Event Subtitle (Optional): </h3>
							<InputBox
								value={event.subtitle}
								maxLength={250}
								onChange={(value: string) => handleChange(value, "subtitle")}
							/>
						</div>
						<h3>Event Description (Optional):</h3>
						<TextAreaInputBox
							value={event.description}
							maxLength={1245}
							onChange={(value: string) => handleChange(value, "description")}
						/>
					</div>
					<div className={styles.userInputColumn}>
						<h2 className={styles.inputTitle}>Event Banner (Optional)</h2>
						<div className={!bannerImage ? styles.imageContainer : styles.imageUploaded}>
							<label htmlFor="banner-image-upload" className={styles.uploadImageButton} onClick={() => {}}>
								Edit
							</label>
							<input
								type="file"
								id="banner-image-upload"
								accept="image/*"
								onChange={(event) => handleImageUpload(event, setBannerImage, setBannerErrorMsg, "banner")}
								className={styles.uploadImage}
							/>
							{bannerImage && <img className={styles.banner} alt={"Event Search "} src={bannerImage} />}
						</div>
						{bannerErrorMsg && <ErrorMessage message={bannerErrorMsg}/>}
					</div>
				</div>
				<div className={styles.userInputRow}>
					<div className={styles.userInputColumn}>
						<h2 className={styles.inputTitle}>Event Location Information</h2>
						<div className={styles.userInputRow}>
							<div className={styles.inputContainer}>
								<h3>Location Name:</h3>
								<InputBox
									value={event.location}
									maxLength={250}
									onChange={(value: string) => handleChange(value, "location")}
								/>
								{renderErrors("location")}
							</div>
							<div className={styles.inputContainer}>
								<h3>Street Address:</h3>
								<InputBox
									value={street}
									maxLength={200}
									onChange={(value: string) => handleChange(value, "address", "street", address)}
								/>
								{renderAddressErrors("street")}
							</div>
						</div>
						<div className={styles.userInputRow}>
							<div className={styles.inputContainer}>
								<h3>City:</h3>
								<InputBox
									value={city}
									maxLength={100}
									onChange={(value: string) => handleChange(value, "address", "city", address)}
								/>
								{renderAddressErrors("city")}
							</div>
							<div className={styles.inputContainer}>
								<h3>State:</h3>
								<Dropdown
									options={states}
									value={state}
									renderOption={(option: any, index: number) => {
										const { name, abbreviation } = option;

										return (
											<option key={index} value={name}>
												{abbreviation} - {name}
											</option>
										);
									}}
									onChange={(value: string) => handleChange(value, "address", "state", address)}
								/>
								{renderAddressErrors("state")}
							</div>
						</div>
						<div className={styles.zipCode}>
							<h3>Zip Code:</h3>
							<InputBox
								value={zip}
								maxLength={5}
								onChange={(value: string) => {
									validateZipCode(value);
									handleChange(value, "address", "zip", address);
								}}
							/>
							{renderAddressErrors("zip")}
						</div>
					</div>
					<div className={styles.userInputColumn}>
						<h2 className={styles.inputTitle}>Event Search Image (Optional)</h2>
						<div className={!searchImage ? styles.imageContainer : styles.imageUploaded}>
							<label htmlFor="search-image-upload" className={styles.uploadImageButton} onClick={() => {}}>
								Edit
							</label>
							<input
								type="file"
								id="search-image-upload"
								accept="image/*"
								onChange={(event) => handleImageUpload(event, setSearchImage, setSearchImageError, "search_image")}
								className={styles.uploadImage}
							/>
							{searchImage && <img className={styles.banner} alt={"Event Search "} src={searchImage} />}
						</div>
						{searchImageError && <ErrorMessage message={searchImageError}/>}
					</div>
				</div>
				<div className={styles.buttonRow}>
					<SmallButton
						onClick={() => {
							onBack();
						}}
					>
						Back
					</SmallButton>
					<div className={styles.continue}>
						<SmallButton
							onClick={() => {
								handleContinue();
							}}
						>
							Continue
						</SmallButton>
						{!isFormComplete && displayErrors && (
							<p className={styles.errorMessageSubmit}>{"Please fix your errors before submitting!"}</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default GeneralEventInfo;
