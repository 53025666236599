import styles from "./index.module.css";

const ErrorCode400 = () => {
	return (
		<div className={styles.container}>
			<h1 className={styles.title}>400 Something went wrong.</h1>
			<p className={styles.text}>Go back or try again later.</p>
		</div>
	);
};

export default ErrorCode400;
