import { useState, useEffect } from "react";
import styles from "./index.module.css";
import SmallButton from "components/small_button/small_button";
import { useUser } from "state/useUser";
import { api } from "axiosClients/client";
import Dropdown from "components/dropdown";
import { format_date } from "../accounting/books_page/books";
import TicketBox from "pages/events/tickets_page/ticket_box";
import common_style from "../../../../../../../common/css/common.module.css";
import CardPointOfSaleForm from "./card_point_of_sale_form";
import color_palette from "../../../../../../../common/types/colors";
import BillingSummaryBox from "../../../../../../../components/billing_summary_components/billing_summary_box";
import { TicketPageOptions, TransactionType } from "common";
import { useElements, CardCvcElement, CardNumberElement, CardExpiryElement } from "@stripe/react-stripe-js";


const PointOfSale = () => {
	const upcomingEvents = useUser((state) => state.upcomingEvents);
	const pastEvents = useUser((state) => state.pastEvents);
	const organization = useUser((state) => state.userOrganization);
	const setUpcomingEvents = useUser((state) => state.setUpcomingEvents);
	const setPastEvents = useUser((state) => state.setPastEvents);
	// Just in case some of the past events only have a start date
	const all_events = upcomingEvents.concat(pastEvents.slice(0, 3));

	const [local_num_tickets, set_local_num_tickets] = useState<Array<number>>();
	const [selected_event, set_selected_event] = useState<FullEvent | undefined>();
	const [selected_tickets, set_selected_tickets] = useState<Array<SelectedTicket>>([]);
	const [transaction_type, set_transaction_type] = useState<TransactionType>(TransactionType.Card);
	const [reset, set_reset] = useState<boolean>(false);
	const [loading, set_loading] = useState<boolean>(false);
	const elements = useElements();
	const [input_error, set_input_error] = useState<string>("");
	const [purchase_failure, set_purchase_failure] = useState<boolean>(false);
	const [error, set_error] = useState<string>("");
	const is_paid =
		selected_tickets.map(({ ticket }) => Number(ticket.price.$numberDecimal)).reduce((a, b) => a + b, 0) !== 0 ||
		JSON.stringify(selected_tickets) === JSON.stringify([]);
	const compute_total_cost = (ticket_price: number, quantity: number) => {
		let sales_tax: number = 0; // in the future we need to implement some sales tax calculation functionality...
		let final_cost: any = quantity * (ticket_price + ticket_price * sales_tax);

		final_cost = final_cost.toFixed(2).toString().split("").reverse();

		let final_cost_cents: any = final_cost.slice(0, 3);
		final_cost = final_cost.splice(3, final_cost.length - 1);

		let formatted_cost: Array<string> = [];

		for (let i: number = 0; i < final_cost.length; i++) {
			formatted_cost.push(final_cost[i]);
			if (i % 3 === 2 && i !== final_cost.length - 1) {
				formatted_cost.push(",");
			}
		}

		return formatted_cost.reverse().join("") + final_cost_cents.reverse().join("");
	};

	// Helper function to add a ticket for purchase
	const on_add_ticket = (added_ticket: TicketType) => {
		let tickets: Array<SelectedTicket> = new Array(...selected_tickets);
		let ticket_type_idx: number = -1;

		// check to see if a ticket of this type has already been added
		tickets.map(({ ticket }, idx) => {
			if (ticket.ticket_name === added_ticket.ticket_name && ticket.ticket_tier === added_ticket.ticket_tier) {
				ticket_type_idx = idx;
			}
			return ticket;
		});

		// Added the new ticket or increment the quantity of that type of ticket
		if (ticket_type_idx === -1) {
			tickets.push({ ticket: added_ticket, quantity: 1 });
		} else {
			tickets[ticket_type_idx].quantity += 1;
		}

		set_selected_tickets(tickets);
	};
	// Helper function to remove a ticket
	const on_remove_ticket = (added_ticket: TicketType) => {
		let tickets: Array<SelectedTicket> = new Array(...selected_tickets);
		let ticket_type_idx: number = -1;

		// check to see if a ticket of this type has already been added
		tickets.map(({ ticket }, idx) => {
			if (ticket.ticket_name === added_ticket.ticket_name && ticket.ticket_tier === added_ticket.ticket_tier) {
				ticket_type_idx = idx;
			}
			return ticket;
		});

		// Added the new ticket or increment the quantity of that type of ticket
		if (tickets[ticket_type_idx].quantity === 1) {
			tickets = tickets.filter((item, idx) => ticket_type_idx !== idx);
		} else {
			tickets[ticket_type_idx].quantity -= 1;
		}

		set_selected_tickets(tickets);
	};
	const on_submit = (stripe_token?: any, user_information?: UserInformation) => {
		set_loading(true);
		set_error("");
		set_purchase_failure(false);
		api({
			url: "/point_of_sale",
			method: "POST",
			responseType: "blob",
			data: {
				orders: selected_tickets?.map((order) => {
					return {
						id: order.ticket._id,
						quantity: order.quantity,
					};
				}),
				organization: organization,
				stripe_token: stripe_token,
				event: selected_event,
				user: user_information,
				payment_type: !is_paid ? TransactionType.Free : transaction_type,
			},
		})
			.then((res) => {
				const data = res.data;
				if (res.status === 200) {
					on_success(data);
					set_reset(true);
				} else {
					set_purchase_failure(true);
					set_loading(false);
					if (data.error.raw.message) {
						set_error(data.error.raw.message);
					}
				}
			})
			.catch((err) => {
				set_purchase_failure(true);
				set_loading(false);
				if (err.response) {
					if (err?.response?.data?.error?.raw?.message) {
						set_error(err.response.data.error.raw.message);
					} else {
						set_error(err.response.data.message);
					}
				}
			});
	};
	const on_success = async (data: any) => {
		set_error("");
		set_purchase_failure(false);
		set_loading(false);
		set_selected_tickets([]);
		if(elements === null) return;
		const cardNumberElement = elements.getElement(CardNumberElement);
		const cardExpiryElement = elements.getElement(CardExpiryElement);
		const cardCvcElement = elements.getElement(CardCvcElement);
		cardCvcElement?.clear();
		cardNumberElement?.clear();
		cardExpiryElement?.clear();
		
		const url = URL.createObjectURL(data);
		window.open(url);
	};
	useEffect(() => {
		api.post("/get_all_organization_events")
			.then((res) => {
				const data = res.data;
				if (data.upcoming_events) {
					setUpcomingEvents(data.upcoming_events);
					set_selected_event(data.upcoming_events[0]);
				}
				if (data.past_events) {
					setPastEvents(data.past_events);
				}
			})
			.catch((err: any) => {
				set_purchase_failure(false);
				set_error("Error: Could not get events");
			});
	}, []);
	useEffect(() => {
		if (selected_event) set_error("");
		set_local_num_tickets(
			selected_event?.ticket_types.map((ticket_option) => {
				return ticket_option.number_of_tickets;
			})
		);
	}, [selected_event]);
	useEffect(() => {
		if (!is_paid) {
			set_transaction_type(TransactionType.Free);
		}
	}, [is_paid]);

	return (
		<div className={styles.pos_root}>
			<h1 style={{ margin: "0px" }}>Point of Sale</h1>
			<h2>Choose an event to sell tickets for.</h2>
			<Dropdown
				options={all_events}
				onChange={(value: string) => {
					set_selected_tickets([]);
					set_selected_event(all_events[Number(value)]);
				}}
				renderOption={(option: FullEvent, index: any) => {
					return (
						<option value={index} key={option + index}>
							{option.name + " / " + option.location + " / " + format_date(option.event_start_time)}
						</option>
					);
				}}
			/>
			<div className={styles.tickets_section}>
				<h2 className="my-[10px]">Available Tickets</h2>
				{selected_event && local_num_tickets ? (
					<>
						{selected_event?.ticket_types?.length > 0 ? (
							selected_event?.ticket_types?.map((ticket, idx) => {
								const ticket_price = Number(ticket.price.$numberDecimal);
								if (local_num_tickets[idx] === 0) {
									return (
										<p
											className={`${common_style.common_medium} ${common_style.common_font_medium}`}
											style={{
												marginTop: "14px",
											}}
										>
											No tickets available for this event.
										</p>
									);
								}
								return (
									<TicketBox
										key={ticket._id}
										name={ticket.ticket_name}
										page={TicketPageOptions.POS}
										ticket_price={ticket_price}
										ticket_tier={ticket.ticket_tier}
										number_of_tickets={ticket.number_of_tickets}
										tickets_left={local_num_tickets[idx]}
										onClick={() => {
											const idx = selected_event.ticket_types.findIndex(
												(selected_ticket) => selected_ticket._id === ticket._id
											);

											let updated_local_num_tickets = new Array(local_num_tickets)[0];

											updated_local_num_tickets[idx] -= 1;

											set_local_num_tickets(updated_local_num_tickets);

											on_add_ticket(ticket);
										}}
									/>
								);
							})
						) : (
							<p
								className={`${common_style.common_medium} ${common_style.common_font_medium}`}
								style={{ marginTop: "14px" }}
							>
								No tickets available for this event.
							</p>
						)}
					</>
				) : (
					<p className={`${common_style.common_medium} ${common_style.common_font_medium}`} style={{ marginTop: "14px" }}>
						No tickets available for this event.
					</p>
				)}
			</div>
			<div className={styles.tickets_section}>
				<h2 className="my-[10px]">Cart</h2>
				{selected_event && local_num_tickets ? (
					<>
						{selected_tickets.length ? (
							selected_tickets.map(({ ticket, quantity }) => {
								return (
									<TicketBox
										name={ticket.ticket_name}
										ticket_price={Number(ticket.price.$numberDecimal)}
										ticket_tier={ticket.ticket_tier}
										onClick={() => {
											const idx = selected_event.ticket_types.findIndex(
												(selected_ticket) => selected_ticket._id === ticket._id
											);

											let updated_local_num_tickets = new Array(local_num_tickets)[0];

											updated_local_num_tickets[idx] += 1;

											set_local_num_tickets(updated_local_num_tickets);
											on_remove_ticket(ticket);
										}}
										selected_ticket={true}
										quantity={quantity}
									/>
								);
							})
						) : (
							<p
								className={`${common_style.common_medium} ${common_style.common_font_medium}`}
								style={{ marginTop: "14px" }}
							>
								When you add tickets, they'll appear here!
							</p>
						)}
					</>
				) : (
					<p className={`${common_style.common_medium} ${common_style.common_font_medium}`} style={{ marginTop: "14px" }}>
						When you add tickets, they'll appear here!
					</p>
				)}
			</div>
			<div className={styles.checkout}>
				<h3>Payment Options</h3>
				<div className={styles.payment_options}>
					{(is_paid || selected_tickets.length === 0) && (
						<>
							<SmallButton
								selected={transaction_type === TransactionType.Card}
								active_color={color_palette.dark_blue}
								onClick={() => {
									set_transaction_type(TransactionType.Card);
								}}
							>
								Card
							</SmallButton>
							<SmallButton
								selected={transaction_type === TransactionType.Cash}
								active_color={color_palette.dark_blue}
								onClick={() => {
									set_transaction_type(TransactionType.Cash);
								}}
							>
								Cash
							</SmallButton>
						</>
					)}
					<SmallButton
						selected={transaction_type === TransactionType.Free}
						active_color={color_palette.dark_blue}
						onClick={() => {
							set_transaction_type(TransactionType.Free);
						}}
					>
						Free
					</SmallButton>
				</div>
				<div className={styles.row}>
					{transaction_type === TransactionType.Card && (is_paid || selected_tickets.length === 0) && (
						<div className={styles.payment}>
							<h3>Card Payment</h3>
							<CardPointOfSaleForm
								reset={reset}
								set_reset={set_reset}
								disabled={selected_tickets.length === 0}
								onSubmit={(billing_information, user_information) =>
									on_submit(billing_information, user_information)
								}
								error={input_error}
								set_error={set_input_error}
								purchase_failure={purchase_failure}
								set_purchase_failure={set_purchase_failure}
							/>
						</div>
					)}
					{transaction_type === TransactionType.Cash && (is_paid || selected_tickets.length === 0) && (
						<div className={styles.payment}>
							<h3>Cash Payment</h3>
							<div className={styles.box}>
								<h3>Paid in Cash</h3>
								<div className={styles.button}>
									<SmallButton disabled={selected_tickets.length === 0} onClick={() => on_submit()}>
										Buy Tickets and Print
									</SmallButton>
								</div>
							</div>
						</div>
					)}
					{transaction_type === TransactionType.Free && (
						<div className={styles.payment}>
							<h3>Free</h3>
							<div className={styles.box}>
								<h3>Free</h3>
								<div className={styles.button}>
									<SmallButton disabled={selected_tickets.length === 0} onClick={() => on_submit()}>
										Buy Tickets and Print
									</SmallButton>
								</div>
							</div>
						</div>
					)}
					<div className={styles.cost_breakdown}>
						<h3>Cost Breakdown</h3>
						<BillingSummaryBox
							selected_tickets={selected_tickets}
							donation_amount={0}
							promo_discount={undefined}
							transaction_type={transaction_type}
						/>
					</div>
					{purchase_failure && (
						<p
							className={`${styles.warning_bottom} ${common_style.common_bold}`}
							style={{
								color: color_palette.red,
							}}
						>
							There was an issue confirming your purchase. {error}
						</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default PointOfSale;
