import React, { useState } from "react";

import color_palette from "common/types/colors";

import { ButtonTypes } from "common";

type ButtonColors = {
	border?: string;
	backgroundColor?: string;
	color?: string;
};

type Props = {
	onClick?: Function;
	children: React.ReactNode;
	className?: string;
	disabled?: boolean;
	selected?: boolean;
	default_color?: string;
	hover_color?: string;
	active_color?: string;
	type?: ButtonTypes;
};

const SmallButton = ({
	onClick = () => {},
	children,
	className,
	disabled,
	selected,
	default_color,
	hover_color,
	active_color,
	type,
}: Props) => {
	const disabled_style: ButtonColors = {
		border: `2px solid ${color_palette.disable_gray}`,
		backgroundColor: "transparent",
		color: color_palette.disable_gray,
	};

	const default_style: ButtonColors = {
		border: `2px solid ${default_color ?? color_palette.medium_blue}`,
		backgroundColor: "transparent",
		color: default_color ?? color_palette.medium_blue,
	};

	const hover_style: ButtonColors = {
		border: `2px solid ${hover_color ?? color_palette.dark_blue}`,
		backgroundColor: hover_color ?? color_palette.dark_blue,
		color: color_palette.white,
	};

	const active_style: ButtonColors = {
		border: `2px solid ${active_color ?? color_palette.light_blue}`,
		backgroundColor: active_color ?? color_palette.light_blue,
		color: color_palette.white,
	};

	const [color_style, set_color_style] = useState<ButtonColors>(default_style);

	const button_style: string =
		className ??
		`
			font-custom 
			text-regular 
			w-[235px] 
			h-8 
			border-[3px] 
			bg-transparent 
			rounded-md 
		`;

	return (
		<button
			onClick={() => onClick()}
			className={button_style}
			disabled={disabled ?? false}
			style={disabled ? disabled_style : selected ? active_style : color_style}
			onMouseEnter={() => set_color_style(hover_style)}
			onMouseLeave={() => set_color_style(default_style)}
			onMouseDown={() => set_color_style(active_style)}
			onMouseUp={() => set_color_style(default_style)}
			type={type ?? ButtonTypes.Submit}
		>
			{children}
		</button>
	);
};

export default SmallButton;
