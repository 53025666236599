import { useNavigate } from "react-router-dom";

import BillingSummaryBox from "components/billing_summary_components/billing_summary_box";
import ErrorCode400 from "components/error_displays/400_error_code";
import SmallButton from "components/small_button/small_button";

import { use_events } from "state/use_events";

import order_summary_style from "./order_summary_page.module.css";
import common_style from "common/css/common.module.css";

import color_palette from "common/types/colors";

import { DonationType } from "common";

const OrderSummary = () => {
	const { reset_ticket_purchase_state, event, tickets, organization, donation, selected_event, promo_discount } = use_events(
		(state) => ({
			reset_ticket_purchase_state: state.reset_ticket_purchase_state,
			event: state.selected_event,
			tickets: state.tickets_for_checkout,
			organization: state.selected_organization,
			donation: state.donation,
			selected_event: state.selected_event,
			promo_discount: state.promo_discount,
		})
	);

	const navigate = useNavigate();

	const { primary_color, secondary_color } = selected_event?.color_scheme ?? {
		primary_color: undefined,
		secondary_color: undefined,
	};

	const months = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];

	const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

	const format_date = (date: Date) => {
		const js_date = new Date(date);

		return months[js_date.getMonth()] + " " + js_date.getDate();
	};

	const format_day_time = (date: Date) => {
		const js_date = new Date(date);

		return (
			days[js_date.getDay()] +
			" @ " +
			js_date.toLocaleTimeString("en-US", {
				hour: "numeric",
				minute: "numeric",
				hour12: true,
			})
		);
	};

	const go_forward = () => {
		reset_ticket_purchase_state();
		navigate("/");
	};

	if (!event || !tickets || !organization) return <ErrorCode400 />;

	return (
		<div className="mx-auto my-4 w-full space-y-8 text-center md:my-8">
			<p className="text-2xl">Thank you for your purchase!</p>
			<p className={`${common_style.common_medium} ${common_style.common_font_medium}`} style={{ color: color_palette.gray }}>
				Check your email inbox for your tickets.
			</p>
			<div className="mx-auto">
				<div>
					<div className="my-4 flex flex-col items-center text-center">
						<p className={`${common_style.common_bold} ${common_style.common_font_large}`}>Event</p>
						<p className={"m-0 h-[30px] max-w-[300px] truncate p-0 py-[10px] md:max-w-[1000px]"}>{event.name}</p>
						<div className={order_summary_style.date_time}>
							<p
								className={"text-left font-custom text-md-header font-bold "}
								style={{ color: primary_color ?? color_palette.medium_blue }}
							>
								{format_date(event.event_start_time)}
							</p>
							<hr className={common_style.common_bar_tall} color={color_palette.gray} />
							<p className={`${common_style.common_bold} ${common_style.common_font_medium}`}>
								{format_day_time(event.event_start_time)}
							</p>
						</div>
					</div>
					<div className="mx-auto flex w-11/12 flex-col md:w-4/12">
						<h1
							className={`${order_summary_style.header} ${common_style.common_bold} ${common_style.common_font_large}`}
						>
							Tickets
						</h1>
						<BillingSummaryBox
							selected_tickets={tickets.map(({ ticket, quantity }, key) => ({
								key,
								ticket,
								quantity: Number(quantity),
							}))}
							donation_amount={donation ? Number(donation.donation_cost) : 0}
							donation_type={
								selected_event?.donation_type === DonationType.Flat ||
								selected_event?.donation_type === DonationType.Percentage
									? selected_event?.donation_type
									: undefined
							}
							promo_discount={promo_discount}
						/>
					</div>
					{selected_event?.donations && donation?.donation_cost !== 0 && (
						<div className={"flex w-full flex-col items-center"}>
							<h1
								className={`${order_summary_style.header} ${common_style.common_bold} ${common_style.common_font_large}`}
							>
								Donation
							</h1>
							<div
								className={`${order_summary_style.donation_row} ${common_style.common_medium} ${common_style.common_font_medium}`}
							>
								{selected_event?.donation_type === "percentage" && (
									<div
										className={order_summary_style.donation_row}
										style={{
											marginRight: "15px",
										}}
									>
										<span
											className={`${common_style.common_bold} ${common_style.common_font_medium}`}
											style={{
												marginRight: "5px",
											}}
										>
											Donation Choice:
										</span>{" "}
										{donation?.donation_amount}{" "}
									</div>
								)}
								<div className={order_summary_style.donation_row}>
									<span
										className={`${common_style.common_bold} ${common_style.common_font_medium}`}
										style={{
											marginRight: "5px",
										}}
									>
										Donation:
									</span>{" "}
									${donation?.donation_cost?.toFixed(2)}
								</div>
							</div>
							{selected_event.donation_type === "percentage" && (
								<p>
									Please note that the donation percentage is applied to the cost <b>BEFORE</b> the fee is added.
								</p>
							)}
						</div>
					)}
				</div>
			</div>
			<SmallButton
				default_color={primary_color}
				hover_color={secondary_color}
				active_color={primary_color}
				onClick={() => go_forward()}
			>
				Events
			</SmallButton>
		</div>
	);
};

export default OrderSummary;
