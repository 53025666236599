import React from "react";

import styles from "./metrics.module.css";
import dropdown_arrow from "../../../../../../../common/icons/dropdown_arrow.svg";

const Metrics = () => {
	return (
		<div className={styles.adminContent}>
			<div className={styles.header}>
				<h2 className={styles.headerTitle}>Metrics For</h2>
				<div className={styles.filters}>
					<div className={styles.row}>
						Event Name
						<div className={styles.filterInput}>
							<span className={styles.plain}>
								Search for an event by name...
							</span>
						</div>
					</div>
					<div className={styles.row}>
						Time
						<div className={styles.filterInput}>
							Start Date:{" "}
							<span className={styles.plain}>None</span>
							End Date:{" "}
							<span className={styles.plain}>None</span>
						</div>
					</div>
					<div className={styles.row}>
						Location
						<div className={styles.filterInput}>
							<span className={styles.plain}>
								Anywhere
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.dropdownRow}>
				<h3 style={{ margin: "0px" }}>
					Ticket Accounting Information
				</h3>
				<img
					className={styles.dropdownArrow}
					src={dropdown_arrow}
					alt={"Dropdown Arrow"}
				/>
			</div>
			<h3 className={styles.headerTitle}>Admission Overview</h3>
			<div className={styles.infoRow}>
				<p className={styles.itemsSold}>
					{" "}
					<span className={styles.infoTitle}>
						Total Number of Items Sold:{" "}
					</span>
					4,532
				</p>
				<p style={{ marginBottom: "40px", margin: "0px" }}>
					{" "}
					<span className={styles.infoTitle}>
						Total Income from Sales:{" "}
					</span>
					$226,254
				</p>
			</div>
			<h3 className={styles.headerTitle}>Tickets Sold By Type</h3>
			<div className={styles.infoRow}>
				<div className={styles.metricRow}>
					<div className={styles.darkBox}></div>General Admission
				</div>
				<div className={styles.metricRow}>
					<div className={styles.mediumBox}></div>General
					Admission
				</div>
				<div className={styles.metricRow}>
					<div className={styles.lightBox}></div>General
					Admission
				</div>
			</div>
		</div>
	);
};

export default Metrics;
