import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import CommonBarSmall from "components/bars/common_bar_small";
import Dropdown from "components/dropdown";
import EventCard from "pages/events/home_page/event_card";
import InputBox from "components/input_box";
import Loader from "partials/loader/loader";
import SearchInputBox from "components/search_input_box";
import SmallButton from "components/small_button/small_button";

import { use_events, UseEventsState } from "state/use_events";
import { useUser, UseUserState } from "state/useUser";

import { api } from "axiosClients/client";
import { get_current_jwt } from "util/user";
import color_palette from "common/types/colors";

import common_style from "common/css/common.module.css";
import default_image from "common/images/default_concert_stage_image3.png";
import home_style from "pages/events/home_page/home.module.css";

import { ButtonTypes, Genre } from "common";

type Filters = {
	name: string;
	location: string;
	genre: string;
};

type FilterMap = {
	name: string;
	value: string;
};

const genres: Array<Genre> = [
	Genre.None,
	Genre.Sports,
	Genre.Theatre,
	Genre.Comedy,
	Genre.Arts,
	Genre.Concerts,
	Genre.Festivals,
	Genre.Symphony,
	Genre.Conferences,
	Genre.Music,
];

type FilterObject = {
	name: string;
	location: string;
	genre: string;
};

const default_filters: Filters = {
	name: "",
	location: "",
	genre: "",
};
const Home = () => {
	const { getValues, handleSubmit, register, reset, setValue } = useForm<Filters>({
		defaultValues: default_filters,
		mode: "onSubmit",
		reValidateMode: "onSubmit",
	});

	const [filters, set_filters] = useState<Array<FilterMap>>([]);
	const [location_filter, set_location_filter] = useState<string>("");
	const [loading, set_loading] = useState<boolean>(true);

	const set_events_state = use_events((state: UseEventsState) => state.set_all_events_data);
	const set_ticket_options = use_events((state: UseEventsState) => state.set_ticket_options);
	const set_selected_organization = use_events((state: UseEventsState) => state.set_selected_organization);
	const set_selected_event = use_events((state: UseEventsState) => state.set_selected_event);
	const events = use_events((state: UseEventsState) => state.searched_events);
	const organizations = use_events((state: UseEventsState) => state.searched_organizations);
	const tickets = use_events((state: UseEventsState) => state.searched_tickets);
	const setUser = useUser((state: UseUserState) => state.setUser);

	const navigate = useNavigate();

	const custom_search_input_style: string = `
		m-0 
		mb-4  
		h-[35px] 
		w-[98%] 
		text-center 
		font-custom 
		font-normal 
		text-regular 
		text-fangarde-black 
		p-[2px] 
		rounded-md 
		focus:outline-0 
		bg-search-icon 
		bg-no-repeat 
		bg-[1.5%] 
		bg-[length:23px_23px] 
	`;
	const custom_general_input_style: string = `
		m-0
		mb-4 
		h-[35px] 
		w-[98%] 
		text-center 
		font-custom 
		font-normal 
		text-regular 
		text-fangarde-black 
		p-[2px] 
		rounded-md 
		focus:outline-0 
	`;

	// Helper function to search based on query inputs
	const run_query = async () => {
		set_loading(true);

		const { name, location, genre } = getValues();

		let filter_array: Array<FilterMap> = []; // this array is for setting the filters in the UI
		let filters: FilterObject = {
			name: "",
			location: "",
			genre: "",
		}; // this is to make filtering the actual events a lot easier

		if (name !== "") {
			filter_array.push({
				name: "Name",
				value: name,
			});
			filters.name = name;
		}

		if (location !== "") {
			set_location_filter(location);
			filter_array.push({
				name: "Location",
				value: location,
			});
			filters.location = location;
		} else if (location === "") {
			set_location_filter("");
		}

		if (genre !== "") {
			filter_array.push({
				name: "Genre",
				value: genre,
			});
			filters.genre = genre;
		}

		reset();

		set_filters(filter_array);

		try {
			const res = await api.post("/events_search_all", {
				query: filters,
			});

			const { orgs, tix } = res.data;
			let events = res.data?.events;

			if (events) {
				events = events.map((event: any) => {
					const { banner, search_image } = event;

					return {
						...event,
						banner: banner ? banner : default_image,
						search_image: search_image ? search_image : default_image,
					};
				});

				set_events_state(events, orgs, tix);
				set_loading(false);
			} else {
				set_events_state([], [], []);
				set_loading(false);
			}
		} catch (error) {
			set_events_state([], [], []);
			set_loading(false);
		}
	};

	// Helper function to handle when events are selected
	const event_selected = (selected_event: EventModel) => {
		let selected_org = organizations.filter((organization) => organization._id === selected_event.organization_id);
		let selected_tix = tickets.filter((ticket) => ticket.event_id === selected_event._id);

		set_ticket_options(selected_tix);
		set_selected_event(selected_event);
		set_selected_organization(selected_org[0]);

		navigate("/events/event_overview");
	};

	const fetch_user_account_data = async () => {
		api.post("/fetch_account")
			.then((res) => {
				if (res.status === 200) {
					set_loading(false);
					const { data } = res;
					if (data.organization) {
						setUser(data.user);
					}
					return setUser(data.user);
				}
			})
			.catch((err) => {
				set_loading(false);
				console.log(err);
			});
	};

	const fetch_events_init = async () => {
		try {
			const res = await api.get("/events_fetch_init");
			const { orgs, tix } = res.data;
			let events = res.data?.events;

			if (events) {
				events = events.map((event: any) => {
					const { banner, search_image } = event;

					return {
						...event,
						banner: banner ? banner : default_image,
						search_image: search_image ? search_image : default_image,
					};
				});

				set_events_state(events, orgs, tix);
				set_loading(false);
			} else {
				set_events_state([], [], []);
				set_loading(false);
			}
		} catch (err) {
			set_events_state([], [], []);
			set_loading(false);
		}
	};

	useEffect(() => {
		const token = get_current_jwt();

		if (token) {
			fetch_user_account_data();
		}

		try {
			fetch_events_init();
		} catch (error) {
			set_loading(false);
		}
	}, []);

	function SearchBox() {
		return (
			<form
				className="flex w-full flex-col items-center rounded-md border-2 border-medium-blue py-4 md:w-4/12"
				onSubmit={handleSubmit(run_query)}
			>
				<SearchInputBox
					{...register("name")}
					className={custom_search_input_style}
					type={"text"}
					placeholder={"Search by venue, event name, etc..."}
					onChange={(value: string) => setValue("name", value)}
					maxLength={35}
					default_color={"transparent"}
					hover_color={color_palette.medium_blue}
				/>
				<InputBox
					{...register("location")}
					className={custom_general_input_style}
					type={"text"}
					placeholder={"Filter by city or zip code..."}
					onChange={(value: string) => setValue("location", value)}
					maxLength={35}
					default_color={"transparent"}
					hover_color={color_palette.medium_blue}
				/>
				<Dropdown
					{...register("genre")}
					className={custom_general_input_style}
					placeholder={"Filter by genre of entertainment..."}
					options={genres}
					onChange={(value: string) => {
						setValue("genre", value);
					}}
					renderOption={(option: any, index: number) => {
						return (
							<option value={option} key={index}>
								{option}
							</option>
						);
					}}
					default_color={"transparent"}
					hover_color={color_palette.medium_blue}
				/>
				<SmallButton type={ButtonTypes.Submit}>Search</SmallButton>
			</form>
		);
	}

	function UpcomingEvents() {
		return (
			<div className="mx-auto flex flex-col space-y-4 text-center md:w-5/6">
				<h3 className="text-left text-2xl font-semibold" style={{ color: color_palette.black }}>
					Upcoming Events
				</h3>
				{loading ? (
					<div className={home_style.loader}>
						<Loader />
					</div>
				) : (
					<div className="mx-auto grid grid-cols-1 flex-col items-center gap-4 text-left sm:grid-cols-2 md:grid-cols-3">
						{events.length > 0 ? (
							events.map((event: any, key: number) => {
								return (
									<div key={key}>
										<EventCard
											key={key}
											event_date={event.event_start_time}
											event_image={event.search_image}
											event_location={event.location}
											event_name={event.name}
											event_subtitle={event.event_subtitle}
											onClick={() => event_selected(event)}
										/>
									</div>
								);
							})
						) : (
							<p className={home_style.no_events_found}>
								No events found. Please check back later for upcoming events or try refining your search.
							</p>
						)}
					</div>
				)}
			</div>
		);
	}

	function Filters() {
		return (
			<div className="mx-auto flex w-full flex-col space-y-4 md:w-5/6">
				<div className="flex flex-row items-center">
					<h2 className="my-0 mr-[10px] border-r-[3px] border-black pr-[10px] font-semibold">Popular events near</h2>
					<p
						className={`
								${common_style.common_bold}
								${common_style.common_font_large}
							`}
						style={{ color: color_palette.medium_blue }}
					>
						{location_filter}
					</p>
				</div>
				<div className="flex flex-row">
					<div className={home_style.filter_other_left}>
						<h3 className="mr-[10px] border-r-[3px] border-black pr-[10px] font-semibold">Current filters</h3>
					</div>
					{!filters.length ? (
						<p className={home_style.filter_empty_placeholder} style={{ color: color_palette.gray }}>
							None
						</p>
					) : (
						<div className={home_style.filter_other_right}>
							{filters.map((filter, key) => {
								return (
									filter.name !== "Location" && (
										<div className={home_style.filter_row} key={key}>
											<h3
												className="my-0 mr-[10px] border-r-[3px] border-black pr-[10px] font-semibold"
												style={{
													color: color_palette.gray,
												}}
											>
												{filter.name}
											</h3>
											<h3
												style={{
													color: color_palette.medium_blue,
												}}
											>
												{filter.name === "Radius" ? filter.value + " miles" : filter.value}
											</h3>
										</div>
									)
								);
							})}
						</div>
					)}
				</div>
			</div>
		);
	}

	return (
		<div className="w-full space-y-8 px-2" style={{ color: color_palette.black }}>
			<div className="mx-auto mt-4 flex w-full flex-col items-center space-y-2 md:my-14">
				<p className="text-center text-3xl md:mb-8">Know what you're looking for? Search below!</p>
				<SearchBox />
			</div>
			<Filters />
			<UpcomingEvents />
		</div>
	);
};

export default Home;
