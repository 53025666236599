// Helper function to format the address for display
export const format_address = (address: { street: string; zip: string; state: string; city: string }) => {
	const { street, zip, state, city } = address;

	return street + " in " + city + ", " + state + " " + zip;
};

// Helper function to format the date of an event
export const format_date = (date: Date) => {
	const js_date = new Date(date);

	return js_date.getMonth() + 1 + "/" + (js_date.getDate() + 1) + "/" + js_date.getFullYear();
};

// Helper function to format the time of an event
export const format_time = (date: Date) => {
	const js_date = new Date(date);

	return js_date.toLocaleTimeString("en-US", {
		hour: "numeric",
		minute: "numeric",
		hour12: true,
	});
};
