import { Navigate, Route, Routes } from "react-router-dom";

import { AccountingItem, MenuItem } from "common";
import AccountingRoutes from "pages/account/admin/components/admin_content/components/accounting/accounting";
import AccountingContent from "pages/account/admin/components/admin_content/components/accounting/routes/accounting_content";
import Admin from "pages/account/admin/admin";
import AdminContent from "pages/account/admin/components/admin_content/admin_content";
import CreateEvent from "pages/account/admin/components/admin_content/components/create_event/create_event";
import EventDetails from "pages/account/admin/components/admin_content/components/upcoming_events/event_details/event_details";
import Login from "pages/account/login/login";
import PastEvents from "pages/account/admin/components/admin_content/components/past_events/past_events";
import PastEventDetails from "pages/account/admin/components/admin_content/components/past_events/past_event_details/past_event_details";
import SignUpInit from "pages/account/sign_up_flow/sign_up_flow";
import UpcomingEvents from "pages/account/admin/components/admin_content/components/upcoming_events/upcoming_events";
import NotFound from "components/error_displays/404_not_found";

const AccountRoutes = () => {
	return (
		<Routes>
			<Route path="create_event" element={<CreateEvent />} />
			<Route path="login" element={<Login />} />
			<Route path="sign_up" element={<SignUpInit />} />
			<Route path="*" element={<NotFound />} />
			<Route path="/" element={<Admin />}>
				<Route index element={<Navigate to="/account/info" />} />
				<Route path="info" element={<AdminContent menuItem={MenuItem.account} />} />
				<Route path="organization" element={<AdminContent menuItem={MenuItem.organization} />} />

				<Route path="accounting" element={<AccountingRoutes />}>
					<Route index element={<Navigate to="/account/accounting/books" />} />
					<Route path="books" element={<AccountingContent menuItem={AccountingItem.books} />} />
					<Route path="banking" element={<AccountingContent menuItem={AccountingItem.banking} />} />
				</Route>
				<Route path="past_events" element={<AdminContent menuItem={MenuItem.pastEvents} />}>
					<Route path="" element={<PastEvents />} />
					<Route path="*" element={<PastEventDetails />} />
				</Route>
				<Route path="upcoming_events" element={<AdminContent menuItem={MenuItem.upcomingEvents} />}>
					<Route path="" element={<UpcomingEvents />} />
					<Route path="*" element={<EventDetails />} />
				</Route>
				<Route path="point_of_sale" element={<AdminContent menuItem={MenuItem.point_of_sale} />} />
				<Route path="metrics" element={<AdminContent menuItem={MenuItem.metrics} />} />
				<Route path="event_details" element={<EventDetails />} />
			</Route>
		</Routes>
	);
};

export default AccountRoutes;
