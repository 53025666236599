import { create } from "zustand";

export interface UseEventsState {
	searched_events: EventModel[];
	searched_organizations: Organization[];
	searched_tickets: TicketType[];
	ticket_options: TicketType[];
	selected_event?: EventModel;
	selected_organization: undefined | Organization;
	selected_tickets: undefined | SelectedTicket[];
	tickets_for_checkout: undefined | TicketsForCheckout[];
	paid: undefined | boolean;
	donation: undefined | Donation;
	promo_discount: undefined | number;

	set_all_events_data: (
		searched_events: EventModel[],
		searched_organizations: Organization[],
		searched_tickets: TicketType[]
	) => void;
	set_events: (searched_events: EventModel[]) => void;
	set_organizations: (searched_organizations: Organization[]) => void;
	set_tickets: (searched_tickets: TicketType[]) => void;
	set_ticket_options: (ticket_options?: TicketType[]) => void;
	set_selected_event: (selected_event?: EventModel) => void;
	set_selected_organization: (selected_organization: undefined | Organization) => void;
	set_selected_tickets: (selected_tickets: undefined | SelectedTicket[]) => void;
	set_tickets_for_checkout: (selected_tickets: undefined | TicketsForCheckout[]) => void;
	set_paid: (paid: undefined | boolean) => void;
	set_donation: (donation: undefined | Donation) => void;
	set_promo_discount: (promo_discount: undefined | number) => void;
	reset_ticket_purchase_state: () => void;
}

export const use_events = create<UseEventsState>((set) => ({
	searched_events: [],
	searched_organizations: [],
	searched_tickets: [],
	ticket_options: [],
	selected_event: undefined,
	selected_organization: undefined,
	selected_tickets: undefined,
	tickets_for_checkout: undefined,
	paid: undefined,
	donation: undefined,
	promo_discount: undefined,

	set_all_events_data: (searched_events: EventModel[], searched_organizations: Organization[], searched_tickets: TicketType[]) =>
		set({ searched_events, searched_organizations, searched_tickets }),
	set_events: (searched_events: EventModel[]) => set({ searched_events }),
	set_organizations: (searched_organizations: Organization[]) => set({ searched_organizations }),
	set_tickets: (searched_tickets: TicketType[]) => set({ searched_tickets }),
	set_ticket_options: (ticket_options: undefined | TicketType[]) => set({ ticket_options }),
	set_selected_event: (selected_event?: EventModel) => set({ selected_event }),
	set_selected_organization: (selected_organization: undefined | Organization) => set({ selected_organization }),
	set_selected_tickets: (selected_tickets: undefined | SelectedTicket[]) => set({ selected_tickets }),
	set_tickets_for_checkout: (tickets_for_checkout: undefined | TicketsForCheckout[]) => set({ tickets_for_checkout }),
	set_paid: (paid: undefined | boolean) => set({ paid }),
	set_donation: (donation: undefined | Donation) => set({ donation }),
	set_promo_discount: (promo_discount: undefined | number) => set({ promo_discount }),
	reset_ticket_purchase_state: () =>
		set({
			selected_tickets: undefined,
			selected_organization: undefined,
			selected_event: undefined,
			tickets_for_checkout: undefined,
			donation: undefined,
			promo_discount: undefined,
		}),
}));
