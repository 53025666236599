
const EventNotFound = () => {
	return (
		<div className="flex flex-col items-center justify-center h-[26.2vh] mb-28">
			<h1>Event Not Found</h1>
			<p>Please make sure your unique URL is correct.</p>
		</div>
	);
};

export default EventNotFound;
