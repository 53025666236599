import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../../axiosClients/client";
import { use_events, UseEventsState } from "../../../state/use_events";
import { useEffect, useState } from "react";

import EventOverviewText from "../event_overview_page/event_overview_text";
import EventNotFound from "components/NoEventFound";
import SmallButton from "components/small_button/small_button";
import Loader from "../../../partials/loader/loader";

import { format_address, format_date, format_time } from "../utils";

import color_palette from "../../../common/types/colors";

import DefaultImage from "../../../common/images/default_concert_stage_image3.png";

const UniqueOverview = () => {
	const navigate = useNavigate();
	let { id } = useParams();
	const [loading, set_loading] = useState(true);

	const {
		selected_event,
		selected_tickets,
		set_ticket_options,
		set_selected_organization,
		set_selected_event,
		set_selected_tickets,
	} = use_events((state: UseEventsState) => ({
		selected_event: state.selected_event,
		selected_tickets: state.selected_tickets,
		set_ticket_options: state.set_ticket_options,
		set_selected_organization: state.set_selected_organization,
		set_selected_event: state.set_selected_event,
		set_selected_tickets: state.set_selected_tickets,
	}));

	const { primary_color, secondary_color } = selected_event?.color_scheme ?? {
		primary_color: undefined,
		secondary_color: undefined,
	};

	const go_back = () => {
		set_ticket_options(undefined);
		set_selected_event(undefined);
		set_selected_organization(undefined);

		navigate("/");
	};

	const go_forward = async () => {
		if (selected_tickets && selected_tickets.length > 0) {
			set_selected_tickets([]);
		}

		navigate("/events/buy_tickets");
	};

	const fetch_event = async () => {
		set_loading(true);

		try {
			const res = await api.post(`/fetch_single_event`, {
				event_id: id,
			});

			const selected_event = res.data.selected_event;
			const ticket_options = res.data.ticket_types;
			const organization = res.data.organization;

			set_selected_event(selected_event);
			set_ticket_options(ticket_options);
			set_selected_organization(organization[0]);
		} catch (error) {
			console.log(error);
		}

		set_loading(false);
	};

	useEffect(() => {
		fetch_event();
	}, []);

	function EventInfo() {
		return (
			<div
				className={
					"flex w-full max-w-[300px] flex-col justify-evenly p-2 text-left font-custom text-regular font-normal text-fangarde-black md:ml-4 md:w-1/2 md:max-w-[800px]"
				}
			>
				<h1 className="mb-4 truncate text-center text-lg-header font-semibold md:text-left">{selected_event?.name}</h1>
				<p className={"m-0 mb-[10px] w-full truncate p-0 text-sm-header font-bold"} style={{ color: color_palette.gray }}>
					{selected_event?.subtitle}
				</p>
				<p className={"m-0 mb-[10px] w-full truncate p-0 text-sm-header"} style={{ color: color_palette.gray }}>
					@{selected_event?.location}
				</p>
				<EventOverviewText
					header={"Date"}
					header_color={primary_color}
					text={format_date(
						selected_event?.event_start_time === undefined ? new Date("") : selected_event.event_start_time
					)}
				/>
				<EventOverviewText
					header={"Time"}
					header_color={primary_color}
					text={format_time(
						selected_event?.event_start_time === undefined ? new Date("") : selected_event.event_start_time
					)}
				/>
				<EventOverviewText
					header={"Address"}
					header_color={primary_color}
					text={format_address(selected_event?.address as any)}
				/>
				<EventOverviewText
					header={"Extra Instructions"}
					header_color={primary_color}
					text={selected_event?.extra_info === undefined ? "None" : selected_event.extra_info}
				/>
				<EventOverviewText
					header={"Description"}
					header_color={primary_color}
					text={selected_event?.description === undefined ? "" : selected_event.description}
					wrap={true}
				/>
			</div>
		);
	}

	function EventButtons() {
		return (
			<div className="mx-auto flex w-full flex-row items-center justify-between md:w-[500px]">
				<SmallButton
					default_color={primary_color}
					hover_color={secondary_color}
					active_color={primary_color}
					onClick={go_back}
				>
					Fangarde's Events
				</SmallButton>
				<SmallButton
					default_color={primary_color}
					hover_color={secondary_color}
					active_color={primary_color}
					onClick={go_forward}
				>
					Buy Tickets
				</SmallButton>
			</div>
		);
	}

	if ((!selected_event && loading) || loading)
		return (
			<div className="absolute m-80 mx-auto flex w-full flex-col items-center px-2">
				<Loader />
			</div>
		);
	else if (selected_event)
		return (
			<div className="mx-auto mt-8 flex w-full flex-col justify-center px-2 md:mb-8">
				<div className="mx-auto mb-8 flex h-full flex-col items-start md:flex-row">
					<div className="flex items-center justify-center self-center overflow-hidden rounded-lg bg-[#808080] md:h-[270px] md:w-[350px]">
						<img
							className="mx-auto rounded-lg md:mx-0 md:w-[350px]"
							src={selected_event.banner || DefaultImage}
							alt={"Event Pic"}
						/>
					</div>
					<EventInfo />
				</div>
				<EventButtons />
			</div>
		);
	else return <EventNotFound />;
};

export default UniqueOverview;
