import CommonBarSmall from "components/bars/common_bar_small";
import SmallButton from "components/small_button/small_button";

import color_palette from "common/types/colors";
import { TicketPageOptions } from "../../../common";

type Props = {
	name: string;
	page?: TicketPageOptions;
	ticket_price: number;
	ticket_tier: string;
	onClick: () => void;
	selected_ticket?: boolean; // boolean to change what component displays.
	number_of_tickets?: number;
	quantity?: number | undefined; // if the display is set to "selected", then we need to show quantity
	color?: string;
	tickets_left?: number;
	key?: number;
	default_color?: string;
	hover_color?: string;
	active_color?: string;
};

const TicketBox = ({
	name,
	page = TicketPageOptions.Neither,
	ticket_price,
	ticket_tier,
	onClick,
	selected_ticket = false,
	quantity = undefined,
	color,
	tickets_left,
	key,
	default_color,
	hover_color,
	active_color,
}: Props) => {
	return (
		<div
			key={key}
			className={"flex h-[60px] w-full flex-row items-center justify-between font-custom text-regular font-normal"}
			style={{ color: color_palette.black, borderBottom: `4px solid ${color ?? color_palette.medium_blue}` }}
		>
			<div className={"flex flex-row items-center"}>
				{tickets_left !== undefined && <p>{tickets_left} tickets left</p>}
				{page === TicketPageOptions.POS && <CommonBarSmall />}
				<p>{quantity ? quantity : "$" + ticket_price}</p>
				<CommonBarSmall color={color ?? color_palette.medium_blue} />
				<div className={"flex flex-row items-center"}>
					<span className={"max-w-[100px] truncate md:max-w-[190px]"}>{ticket_tier}</span> /
					<span className={"ml-[5px] max-w-[100px] truncate text-fangarde-gray md:max-w-[190px]"}>{name}</span>
				</div>
			</div>
			{selected_ticket ? (
				<SmallButton
					default_color={color_palette.red}
					hover_color={color_palette.light_red}
					active_color={color_palette.medium_red}
					onClick={onClick}
				>
					Remove
				</SmallButton>
			) : (
				<SmallButton default_color={default_color} hover_color={hover_color} active_color={active_color} onClick={onClick}>
					Add Ticket
				</SmallButton>
			)}
		</div>
	);
};

export default TicketBox;
