import { Outlet } from "react-router-dom";

import AccountingMenu from "./menu/accounting_menu";

import styles from "./accounting.module.css";

// author: Jack Hau

const AccountingRoutes = () => {
	return (
		<div className={styles.adminRoot}>
			<AccountingMenu />
			<Outlet />
		</div>
	);
};

export default AccountingRoutes;
